import {
  faCheckCircle,
  faTimesCircle,
  faHourglassHalf,
  faPenFancy,
  } from '@fortawesome/free-solid-svg-icons';

export const API_URL = "https://contenidohot.net";
//export const API_URL = "http://localhost:8001";
export const HTTP_METHOD = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DEL: 'DEL'
}

export const KEY_STORAGE = {
  USER_LOGIN: "USER_LOGIN",
  USER_TOKEN: "USER_TOKEN",
  USER_REFRESH_TOKEN: "USER_REFRESH_TOKEN",
  USER_PROFILE: "USER_PROFILE",
};

export const THEME_COLOR = {
  primary: "#5F0084",
  secondary: "#C60000",
  //secondary: "#009F00",
  complement: "#BBC400",
};

export const TYPE_MESSAGE = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

export const KEY_STORAGE_INFORMATION = {
  GALERY: "GALERY",
  OFERTS: "OFERTS",
  PAYMENT_METHODS: "PAYMENT_METHODS",
  INFORMATION_CONTACT: "INFORMATION_CONTACT",
  PAGE: "PAGE",
};



export const KEY_STEP_TOUR = {
  STEP_1: "STEP_1",
};



export const statusValidationLabels = {
  A: 'Aprobado',
  P: 'Pendiente',
  R: 'Rechazado',
  M: 'Modificar',
};

export const statusValidationIcons = {
  A: faCheckCircle,
  P: faHourglassHalf,
  R: faTimesCircle,
  M: faPenFancy,
};