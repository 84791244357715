import React, { useState, useCallback, useEffect } from "react";
import "./page.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEarthAmericas,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import PrincipalTemplate from "../commons/template/principalTemplate";
import { useTranslation } from "react-i18next";
import { PageService } from "./page.service";
import { templatesService } from "../../api/ServerApiAxios";
import MessageManager from "../commons/message/messageManager";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TYPE_MESSAGE, KEY_STORAGE_INFORMATION } from "../../utils/Constant";
import Information from "../commons/information/information";
import { useLogin } from "../../hooks/useLogin";

export default function Page() {
  const navigate = useNavigate();
  const { getUrlPage } = useLogin();
  const [selectedImage, setSelectedImage] = useState(null);
  const { t, i18n } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [listTemplate, setListTemplate] = useState([]);
  const [messageManagerState, setMessageManagerState] = useState({
    show: false,
    type: TYPE_MESSAGE.INFO,
    title: "",
    messageText: "",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesData(dataList),
    validationSchema: validationSchemaData(t),
    onSubmit: async (values) => {
      try {
        await PageService("PUT", values);
        handleMessage(
          true,
          null,
          t("saveMessage"),

          TYPE_MESSAGE.SUCCESS
        );
      } catch (error) {
        if (error.message === "exit") {
          navigate("/");
        } else {
          setErrorMessages(error);
        }
      }
    },
  });

  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu(true);
        }}
      />
    );
  };

  const getData = useCallback(async () => {
    try {
      const data = await PageService("GET");
      const templates = await templatesService();
      setDataList(data);
      setListTemplate(templates);
      setErrorMessages([]);
    } catch (error) {
      if (error.message === "exit") {
        navigate("/");
      } else if (error) {
        setErrorMessages(error);
      }
    }
  }, []);

  const handleMessage = (show, title, messageText, type) => {
    setMessageManagerState({
      show: show,
      type: type,
      title: title,
      messageText: messageText,
    });
    setTimeout(() => {
      setMessageManagerState({
        show: false,
        type: TYPE_MESSAGE.INFO,
        title: "",
        messageText: "",
      });
    }, 3000);
  };

  useEffect(() => {
    (async () => {
      getData();
    })();
  }, []);

  const rightButton = () => {
    return (
      <div>
        <FontAwesomeIcon
          icon={faFloppyDisk}
          className="icon-facebook"
          onClick={() => {
            formik.submitForm();
          }}
        />
        <FontAwesomeIcon
          icon={faEarthAmericas}
          className="icon-facebook"
          onClick={() => {
            // setOpenMenu(true);
            window.open(getUrlPage());
          }}
        />
      </div>
    );
  };

  return (
    <PrincipalTemplate
      headerLeft={leftButton()}
      headerRight={rightButton()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t("page")}
      footerActive=""
    >
      <MessageManager
        show={messageManagerState.show}
        title={messageManagerState.title}
        messageText={messageManagerState.messageText}
        type={messageManagerState.type}
        onClose={() => {
          setMessageManagerState({
            show: false,
            type: TYPE_MESSAGE.INFO,
            title: "a",
            messageText: "b",
          });
        }}
      />
      <Information
        title={t("remember")}
        keyStorage={KEY_STORAGE_INFORMATION.PAGE}
      >
        {t("messagePageInfo")}
      </Information>
      <div className="container-page-principal">
        <div className="input-data">
          <div
            className="page-info-url"
            onClick={async () => {
              await navigator.clipboard.writeText(
                "https://contenidohot.net/" + formik.values.slug
              );

              handleMessage(
                true,
                null,
                t("copiedUrl"),

                TYPE_MESSAGE.INFO
              );
            }}
          >
            https://contenidohot.net/{formik.values.slug}
          </div>
          <span>{t("urlName")}</span>
          <div className="item-page-url">
            <input type="text" value={"contenidohot.net/"} disabled />
            <input
              id="slug"
              type="text"
              name="slug"
              onChange={formik.handleChange}
              value={formik.values.slug}
            />
          </div>
        </div>
        {formik.errors.slug ? (
          <div className="error">{formik.errors.slug}</div>
        ) : (
          <div className="error"></div>
        )}
        {/* TODO: esto permite seleccionar el template para activar
        
        <div className="input-data">
          <span>{t("websiteTemplate")} </span>
          <select
            id="template"
            name="template"
            onChange={formik.handleChange}
            value={formik.values.template}
          >
            {listTemplate &&
              listTemplate.map((item) => {
                return (
                  <option key={item.pk} value={item.pk}>
                    {item.alias}
                  </option>
                );
              })}
          </select>
          {formik.errors.template ? (
            <div className="error">{formik.errors.template}</div>
          ) : (
            <div className="error"></div>
          )}
        </div> */}

        {errorMessages &&
          errorMessages.length > 0 &&
          errorMessages.map((err, index) => {
            return (
              <div className="error" key={"err_" + index}>
                {err}
              </div>
            );
          })}
      </div>
    </PrincipalTemplate>
  );
}

function validationSchemaData(t) {
  return Yup.object({
    slug: Yup.string()
      .max(30, t("messageValidation1"))
      .matches(/^[A-Za-z0-99\-\_]*$/, "Website should be a valid URL")
      .required("Debe ingresar el nombre de la pagina"),
    template: Yup.string().required("Debe ingresar la edad"),
  });
}

function initialValuesData(dataInitial) {
  return {
    slug: dataInitial && dataInitial.slug ? dataInitial.slug : "",
    template: dataInitial ? dataInitial.template : "",
  };
}
