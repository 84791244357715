import React, { useState } from "react";
import Logo from "../../../assets/images/logo.png";
import ButtonSocialMedia from "./buttonSocialMedia";
import ButtonEmail from "./buttonEmail";
import TermsConditions from "./termsConditions";
import MessageRegistration from "./messageRegistration";
import MessageStartSession from "./messageStartSession";
import CreateAccount from "../createAccount/createAccount";
import SignEmail from "../signEmail/signEmail";
import "./login.css";

export default function Login(props) {
  const [showSignEmail, setShowSignEmail] = useState(true); // false cuando este activo los botones sociales
  const [showRegistration, setShowRegistration] = useState(false);
  const loginEmail = props.children;

  const openFormLogin = () => {
    setShowSignEmail(true);
    setShowRegistration(false);
  };

  const openRegistration = () => {
    setShowSignEmail(false);
    setShowRegistration(true);
  };

  const closeFormRegistration = () => {
    setShowRegistration(false);
  };

  const closeFormSignEmail = () => {
    setShowSignEmail(false);
  };

  return (
    <div className="login-container">
      <div className="login-sub-container">
        <a href="https://contenidohot.net">
        <img src={Logo} className="logo" />         
        </a>
        {/* 
              NO BORRAR ESTO !!! */}
        {showRegistration && (
          <CreateAccount close={() => closeFormRegistration()} />
        )}

        {showSignEmail && <SignEmail close={() => closeFormSignEmail()} />}

        {!showSignEmail && !showRegistration && (
          <ButtonEmail
            state={() => openFormLogin()}
            close={() => closeFormRegistration()}
          />
        )}
        {/* 
        <div className="divition-section">
          <div></div> <span> o </span> <div></div>
        </div>
        
        <ButtonSocialMedia /> */}
        {!showRegistration && (
          <MessageRegistration state={() => openRegistration()} />
        )}
        {showRegistration && (
          <MessageStartSession state={() => openFormLogin()} />
        )}
        {/*  TODO: descomentar para actiar la seccion de terminos y condiciones
        <TermsConditions /> 
      */}
      </div>
    </div>
  );
}
