import React, {useState, useEffect} from 'react';
import './packItem.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {formatDate} from '../../../utils/DateUtils';
import {
  faPenToSquare,
  faCopy,
  faExternalLinkAlt,
  faImages,
  faRemove,
  faFileWaveform,
  faComment,
} from '@fortawesome/free-solid-svg-icons';

import createDOMPurify from 'dompurify';
import PackForm from './pack-form';
import {Link} from 'react-router-dom';
import {PackService} from '../pack.service.js';
import {TYPE_MESSAGE} from '../../../utils/Constant';
import {useTranslation} from 'react-i18next';
import MessageManager from '../../commons/message/messageManager';
import {KEY_STORAGE} from '../../../utils/Constant';
import {useLocalStorage} from '../../../hooks/useLocalStorage';
import {statusValidationIcons} from '../../../utils/Constant.js';
import {Tooltip} from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css'; // o la ruta correcta del archivo CSS
import ModalDetailCommentsPack
  from '../../ModalDetailCommentsPack/ModalDetailCommentsPack.jsx';

export default function PackItem (props) {
  const {data, deleteItem, updateData} = props;
  const [showComments, setShowComments] = useState (false);
  const {getItem} = useLocalStorage ();
  const [showModalEdit, setShowModalEdit] = useState (false);
  const [profileUser, setProfileUser] = useState ();
  const [valueEdit, setValueEdit] = useState ({});
  const {t} = useTranslation ();
  const [messageManagerState, setMessageManagerState] = useState ({
    show: false,
    type: TYPE_MESSAGE.INFO,
    title: '',
    messageText: '',
  });

  useEffect (
    () => {
      if (showComments) {
        const handlePopState = event => {
          event.preventDefault ();
          setShowComments (false);
        };

        window.addEventListener ('popstate', handlePopState);
        return () => {
          window.removeEventListener ('popstate', handlePopState);
        };
      }
    },
    [showComments]
  );

  useEffect (() => {
    (async () => {
      const dataProfile = await getItem (KEY_STORAGE.USER_PROFILE);
      setProfileUser (dataProfile);
    }) ();
  }, []);

  const handleMessage = (show, title, messageText, type) => {
    setMessageManagerState ({
      show: show,
      type: type,
      title: title,
      messageText: messageText,
    });
    setTimeout (() => {
      setMessageManagerState ({
        show: false,
        type: TYPE_MESSAGE.INFO,
        title: '',
        messageText: '',
      });
    }, 3000);
  };

  const getStatusValidationMessage = statusItem => {
    switch (statusItem) {
      case 'A':
        return {
          status: 'Validado',
          message: 'El pack está aprobado y no se puede editar',
        };
      case 'P':
        return {
          status: 'Pendiente',
          message: 'El pack está pendiente  de aprobación. \n Una vez  aprobado, no se podrá editar',
        };
      case 'R':
        return {
          status: 'Rechazado',
          message: 'El pack fue rechazado por incumplir \n los términos y condiciones',
        };
      case 'M':
        return {
          status: 'Modificado',
          message: 'Se requiere cambiar cierta información \n del pack para que pueda ser aprobado',
        };

      default:
        return '';
    }
  };

  const preview_content = dataInput => {
    if (dataInput.multimedia[0].proposal_type == 'V') {
      return (
        <video controls>
          <source src={dataInput.multimedia[0].file} type="video/mp4" />
          Tu navegador no admite el elemento de video.
        </video>
      );
    }
    if (dataInput.multimedia[0].proposal_type == 'A') {
      return (
        <audio controls>
          <source src={dataInput.multimedia[0].file} type="audio/mpeg" />
          Tu navegador no admite la reproducción de audio.
        </audio>
      );
    }
    if (dataInput.multimedia[0].proposal_type == 'I') {
      return dataInput.multimedia[0] &&
        dataInput.multimedia[0].file instanceof Blob
        ? <img
            alt="not fount"
            className="profile-img-pack-1"
            src={URL.createObjectURL (dataInput.multimedia[0].file)}
          />
        : <img
            alt="not fount"
            className="profile-img-pack-1"
            src={dataInput.multimedia[0].file}
          />;
    }
  };

  return (
    <div className="packContainer">

      {showModalEdit &&
        <PackForm
          isEdit={true}
          value={valueEdit}
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          onChange={item => {
            data.front_page = item.front_page;
            data.multimedia = item.multimedia;
            updateData (data);
          }}
        />}

      <MessageManager
        show={messageManagerState.show}
        title={messageManagerState.title}
        messageText={messageManagerState.messageText}
        type={messageManagerState.type}
        onClose={() => {
          setMessageManagerState ({
            show: false,
            type: TYPE_MESSAGE.INFO,
            title: 'a',
            messageText: 'b',
          });
        }}
      />
      <div className="item-section-pack-column">
        <div className="item-section-pack-column-input">
          <div className={`item-status-multimedia${data.status_validation}`}>
            <FontAwesomeIcon
              icon={statusValidationIcons[data.status_validation]}
              className={`item-status-multimedia${data.status_validation}`}
              onClick={() => {}}
              data-tooltip-id={
                getStatusValidationMessage (data.status_validation).status
              }
            />
            <Tooltip
              style={{zIndex: 10000}}
              offset={10}
              id={getStatusValidationMessage (data.status_validation).status}
              variant="light"
            >
              <div className="tooltip-content">
                <span>
                  {getStatusValidationMessage (data.status_validation).message}
                </span>
              </div>
            </Tooltip>

          </div>
          <span className="item-date-pack">{formatDate (data.created)}</span>
          <div>
            {data.status_validation !== 'A' &&
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="icon-pack-action"
                onClick={() => {
                  setValueEdit (data);
                  setShowModalEdit (true);
                }}
              />}
            {data.status_validation !== 'A' &&
              <FontAwesomeIcon
                icon={faRemove}
                className="icon-pack-action"
                onClick={async () => {
                  if (
                    window.confirm (
                      '¿Estás seguro de que quieres eliminar este Pack?'
                    )
                  ) {
                    await PackService ('DEL', data);
                    deleteItem (data.id);
                  }
                }}
              />}
            {data.status_validation == 'A' &&
              <Link to={`/history-window/${data.id}`}>
                <FontAwesomeIcon
                  icon={faFileWaveform}
                  className="icon-pack-action"
                  onClick={() => {}}
                />
              </Link>}
          </div>
        </div>
      </div>
      {data &&
        data.multimedia &&
        data.multimedia.length > 0 &&
        <div className="item-section-pack-column-img">
          {preview_content (data)}
        </div>}

      {profileUser &&
        profileUser.authorized_sell &&
        <div className="item-section-pack-column">
          <div className="item-section-pack-column-input">
            <div className="item-price-pack">
              <div>
                <span className="item-amount"> COP {data.price}</span>
              </div>
            </div>
          </div>
        </div>}
      <div className="open-child-data">
        <Link to={`/multimedia/${data.id}`}>
          <FontAwesomeIcon icon={faImages} className="icon-pb" />
          Contenido del Pack{' '}
        </Link>
      </div>
      <div className="open-child-data">
        <div
          onClick={() => {
            setShowComments (!showComments);
          }}
        >
          <FontAwesomeIcon icon={faComment} className="icon-pb" />
          Comentarios
        </div>
      </div>
      <div className="item-section-pack-column">
        <span className="item-pack-title">Código de desbloqueo</span>
        <div className="item-section-pack-column-input">
          <input value={data.unlock_code} readOnly />
          <FontAwesomeIcon
            icon={faCopy}
            className="icon-pack-action"
            onClick={async () => {
              await navigator.clipboard.writeText (data.unlock_code);
              handleMessage (
                true,
                null,
                t ('Codigo de debloqueo copiado'),
                TYPE_MESSAGE.INFO
              );
            }}
          />
        </div>
      </div>
      {profileUser &&
        data.status_validation == 'A' &&
        <div className="item-section-pack-column">
          <span className="item-pack-title">URL del pack</span>
          <div className="item-section-pack-column-input">
            <input
              value={`https://client.contenidohot.net/pack-public/${profileUser.slug}/${data.slug}`}
              readOnly
            />
            <FontAwesomeIcon
              icon={faExternalLinkAlt}
              className="icon-pack-action"
              onClick={() => {
                window.open (
                  `https://client.contenidohot.net/pack-public/${profileUser.slug}/${data.slug}`
                );
              }}
            />
            <FontAwesomeIcon
              icon={faCopy}
              className="icon-pack-action"
              onClick={async () => {
                await navigator.clipboard.writeText (
                  `https://client.contenidohot.net/pack-public/${profileUser.slug}/${data.slug}`
                );

                handleMessage (
                  true,
                  null,
                  t ('URL del pack copiada'),
                  TYPE_MESSAGE.INFO
                );
              }}
            />
          </div>
        </div>}

      {/*
      // TODO habilitar para visualizar la url de facebook 
      <div className="item-section-pack-column">
        <span className="item-pack-title">URL del pack - Facebook</span>
        <div className="item-section-pack-column-input">
          <input value={'contenidohot-1.web.app/' + data.slug} readOnly />
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            className="icon-pack-action"
            onClick={() => {
              window.open ('https://contenidohot-1.web.app/' + data.slug);
            }}
          />
          <FontAwesomeIcon
            icon={faCopy}
            className="icon-pack-action"
            onClick={async () => {
              await navigator.clipboard.writeText (
                'https://contenidohot-1.web.app/' + data.slug
              );

              handleMessage (
                true,
                null,
                t ('URL del pack copiada'),
                TYPE_MESSAGE.INFO
              );
            }}
          />
        </div>
      </div> */}
      {/* 
     {data && <div>Comentearios<CommentSystem packId={data.id} /></div>}  */}

      {profileUser &&
        <ModalDetailCommentsPack
          slugModel={data.slug}
          slugPack={data.slug}
          multimedia={data.multimedia}
          //count={count}
          name={profileUser.namePublic}
          userName={profileUser.userPublic}
          urlProfile={profileUser.foto}
          idPack={data.id}
          countLike={0}
          countDroplet={0}
          countDevil={0}
          countHear={0}
          countSmirk={0}
          isLogged={true}
          show={showComments}
          close={() => {
            setShowComments (false);
          }}
        />}

      {data.description &&
        data.description != '<p></p>' &&
        <div className="item-section-pack-column">
          <span className="item-pack-title">Descripción</span>
          {
            <div
              className="item-pack-description"
              dangerouslySetInnerHTML={{
                __html: createDOMPurify.sanitize (data.description),
              }}
            />
          }
        </div>}

      {data.description &&
        data.description == '<p></p>' &&
        <div className="item-section-pack-column">
          <span className="item-pack-title">Descripción</span>
          <div className="item-pack-description">
            <p>-------------</p>
          </div>
        </div>}
    </div>
  );
}
