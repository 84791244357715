import CommentSystem from '../commentSystem/CommentSystem';
import './ModalDetailCommentsPack.css';
const ModalDetailCommentsPack = ({
  slugModel,
  slugPack,
  multimedia,
  count,
  name,
  userName,
  urlProfile,
  idPack,
  countLike = 0,
  countDroplet = 0,
  countDevil = 0,
  countHear = 0,
  countSmirk = 0,
  isLogged = undefined,
  show,
  close,
}) => {
  const getComponent = (url , proposal_type)=> {
    if (proposal_type == 'V') {
      return  <video controls>
      <source src={url} type="video/mp4" />
      Tu navegador no admite el elemento de video.
    </video>
    } else if (proposal_type == 'I') {
      return <img src={urlProfile} />;
    }
  };

  return (
    show &&
    <div className="content-modal-comment-pack">
      <div
        className="backgronnd-pack"
        onClick={() => {
          close ();
        }}
      />
      <div className="content-modal-comment-item">
        <div className="content-title-modal-item">
          <div className="card-header-he">
            <div className="card-header-img">
              <img src={urlProfile}/>
            </div>
            <div className="card-header-name">
              <span>{name}</span>
              <span className="card-username">@{userName}</span>
            </div>
          </div>
          <div
            className="close-modal-pack"
            onClick={() => {
              close ();
            }}
          >
            X
          </div>
        </div>

        <div className="content-modal-comment-item-sub">
          <div className="preview-item-img">
            {getComponent(multimedia[0].file, multimedia[0].proposal_type)}
          </div>
          <div className="contain-comment-client">
            <CommentSystem packId={idPack} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalDetailCommentsPack;
