import axios from "axios";
import { API_URL } from "../utils/Constant";

export async function login(username, password) {
  try {
    const url = `${API_URL}/api/token/`;
    const res = await axios.post(url, { username, password });
    if (res.status === 200 || res.status === 201) {
      return res.data;
    } else if (res.status === 400) {
      throw new Error(res.status);
    } else if (res.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw new Error(res.data);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function registration(username, email, password1, password2) {
  try {
    // const url = `${API_URL}/dj-rest-auth/registration/`;
    // console.log("resolvio la url", url);
    // const res = await axios.post(url, {
    //   username,
    //   email,
    //   password1,
    //   password2,
    // });

    // if (res.status === 200 || res.status === 201) {
    //   return res.data;
    // } else if (res.status === 400) {
    //   throw new Error(res.status);
    // } else if (res.status === 401) {
    //   throw new Error("Unauthorized");
    // } else {
    //   throw new Error(res.data);
    // }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function templatesService() {
  try {
    const url = `${API_URL}/api/templates/`;
    const res = await axios.get(url);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    } else if (res.status === 400) {
      throw new Error(res.status);
    } else if (res.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw new Error(res.data);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function paymentMethodService() {
  try {
    const url = `${API_URL}/api/payment-method-type/`;
    const res = await axios.get(url);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    } else if (res.status === 400) {
      throw new Error(res.status);
    } else if (res.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw new Error(res.data);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function contactTypeService() {
  try {
    const url = `${API_URL}/api/contact-type/`;
    const res = await axios.get(url);
    if (res.status === 200 || res.status === 201) {
      return res.data;
    } else if (res.status === 400) {
      throw new Error(res.status);
    } else if (res.status === 401) {
      throw new Error("Unauthorized");
    } else {
      throw new Error(res.data);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}
