export const validationRules = {
    14: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?zoom\.us/,
      errorMessage: "Ingrese una URL válida de Zoom",
    },
    15: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?ismygirl\.com/,
      errorMessage: "Ingrese una URL válida de IsMyGirl",
    },

    16: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?t\.me/,
      errorMessage: "Ingrese una URL válida de Telegram",
    },
    17: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?[a-zA-Z0-9.-]+\.pinterest\.com/,
      errorMessage: "Ingrese una URL válida de Pinterest",
    },
    18: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?fansly\.com/,
      errorMessage: "Ingrese una URL válida de Fansly",
    },
    19: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?chaturbate\.com/,
      errorMessage: "Ingrese una URL válida de Chaturbate",
    },
    20: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?cam4\.com/,
      errorMessage: "Ingrese una URL válida de Cam4",
    },
    21: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?stripchat\.com/,
      errorMessage: "Ingrese una URL válida de Stripchat",
    },
    22: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?camsoda\.com/,
      errorMessage: "Ingrese una URL válida de Camsoda",
    },
    23: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?bongacams\.com/,
      errorMessage: "Ingrese una URL válida de Bongacams",
    },
    24: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?streamate\.com/,
      errorMessage: "Ingrese una URL válida de Streamate",
    },
    25: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?discord\.com/,
      errorMessage: "Ingrese una URL válida de Discord",
    },
    13: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?youtube\.com/,
      errorMessage: "Ingrese una URL válida de YouTube",
    },
    12: {
      type: "domain",
      pattern: /^(?:\+\d{1,3}\s?)?\(?\d{1,}\)?[-.\s]?\d{1,}[-.\s]?\d{1,}$/,
      errorMessage: "Ingrese un número  válido para WhatsApp",
    },
    11: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?twitter\.com/,
      errorMessage: "Ingrese una URL válida de Twitter",
    },
    10: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?twitch\.tv/,
      errorMessage: "Ingrese una URL válida de Twitch",
    },
    9: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?tiktok\.com/,
      errorMessage: "Ingrese una URL válida de TikTok",
    },
    8: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?snapchat\.com/,
      errorMessage: "Ingrese una URL válida de Snapchat",
    },
    7: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?skype\.com/,
      errorMessage: "Ingrese una URL válida de Skype",
    },
    6: {
        type: "domain",
        pattern: /^(?:\+\d{1,3}\s?)?\(?\d{1,}\)?[-.\s]?\d{1,}[-.\s]?\d{1,}$/,
        errorMessage: "Ingrese un número valido",
    },
    5: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?onlyfans\.com/,
      errorMessage: "Ingrese una URL válida de OnlyFans",
    },
    4: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?m\.me/,
      errorMessage: "Ingrese una URL válida de Messenger",
    },
    3: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?instagram\.com/,
      errorMessage: "Ingrese una URL válida de Instagram",
    },
    2: {
      type: "domain",
      pattern: /^(https?:\/\/)?(www\.)?facebook\.com/,
      errorMessage: "Ingrese una URL válida de Facebook",
    },
    1: {
        type: "domain",
        pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        errorMessage: "Ingrese un correo valido",
    },
  };

  
  