import React, {useEffect, useState} from 'react';
import './CommentSystem.css';
import {formatDistanceToNow} from 'date-fns';
import {es} from 'date-fns/locale';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-solid-svg-icons';
import {
  CommentSystemPack,
  CommentSystemPackComment,
  CommentSystemPackLike,
} from './CommentSystemService';

const Card = ({children}) => <div className="card">{children}</div>;

const Button = ({children, onClick, className}) => (
  <button className={`button ${className}`} onClick={onClick}>
    {children}
  </button>
);

const Input = ({value, onChange, placeholder}) => (
  <input
    type="text"
    value={value}
    onChange={onChange}
    placeholder={placeholder}
    className="input"
  />
);

const Avatar = ({src, alt}) => {
  if (src) {
    return <img src={src} alt={alt} className="avatar" />;
  } else {
    return <div className="avatar-aux"> </div>;
  }
};

const Send = () => (
  <span role="img" aria-label="send">
    {' '}<FontAwesomeIcon icon={faPaperPlane} />
  </span>
);

const Comment = ({comment, onReply, onLike, depth = 0}) => {
  const [showReplyInput, setShowReplyInput] = useState (false);
  const [replyText, setReplyText] = useState ('');

  const handleReply = () => {
    onReply (comment.id, replyText);
    setReplyText ('');
    setShowReplyInput (false);
  };

  return (
    <div
      className={`comment-container ${depth > 0 ? 'nested-comment' : ''}`}
      style={{marginLeft: `${depth * 20}px`}}
    >
      <Avatar src={comment.avatar} alt={comment.author} />
      <div className="comment-content">
        <div className="comment-bubble">
          <p className="author-name">{comment.author}</p>
          <p className="comment-text">{comment.text}</p>
        </div>
        <div className="comment-actions">
          <div>
            <span>
              {formatDistanceToNow (new Date (comment.timestamp), {
                addSuffix: false,
                locale: es,
              })}
            </span>
            <button
              className="action-button"
              onClick={() => setShowReplyInput (!showReplyInput)}
            >
              Responder
            </button>
            <button
              className="action-button"
              onClick={() => onLike (comment.id)}
            >
              Me gusta
            </button>
          </div>
          <span className="count-like">
            {comment.likes > 0 ? comment.likes : 0}
          </span>
        </div>

        {showReplyInput &&
          <div className="reply-container">
            <Input
              value={replyText}
              onChange={e => setReplyText (e.target.value)}
              placeholder="Escribe una respuesta..."
            />
            <Button
              onClick={handleReply}
              className="reply-button"
              style={{marginTop: '8px'}}
            >
              <Send />
            </Button>
          </div>}
        {comment.replies &&
          comment.replies.length > 0 &&
          <CommentReplies
            replies={comment.replies}
            onReply={onReply}
            onLike={onLike}
            depth={depth + 1}
          />}
      </div>
    </div>
  );
};

const CommentReplies = ({replies, onReply, onLike, depth}) => {
  const [expanded, setExpanded] = useState (false);
  if (replies.length === 0) return null;
  return (
    <div>
      {!expanded &&
        <button className="collapse-button" onClick={() => setExpanded (true)}>
          Ver {replies.length} respuesta{replies.length > 1 ? 's' : ''}
        </button>}
      {expanded &&
        <div>
          {replies.map (reply => (
            <Comment
              key={reply.id}
              comment={reply}
              onReply={onReply}
              onLike={onLike}
              depth={depth}
            />
          ))}
          <button
            className="collapse-button"
            onClick={() => setExpanded (false)}
          >
            Ocultar respuestas
          </button>
        </div>}
    </div>
  );
};

const CommentSystem = ({packId}) => {
  const [comments, setComments] = useState ([]);
  const [newComment, setNewComment] = useState ('');

  useEffect (() => {
    (async () => {
      const listComment = await CommentSystemPack ('GET', packId);
      if (listComment && listComment.length > 0) {
        setComments (listComment);
      }
    }) ();
  }, []);

  const handleSubmit = async e => {
    e.preventDefault ();
    if (newComment.trim () !== '') {
      try {
        const comment = await CommentSystemPack ('POST', packId, newComment);
        setComments ([...comments, comment]);
        setNewComment ('');
      } catch (error) {}
    }
  };

  const handleReply = async (parentId, replyText) => {
    const updateComments = async commentsItem => {
      const updatedComments = await Promise.all (
        commentsItem.map (async comment => {
          if (comment.id === parentId) {
            const reply = await CommentSystemPackComment (
              'POST',
              packId,
              replyText,
              parentId
            );
            return {...comment, replies: [...comment.replies, reply]};
          } else if (comment.replies && comment.replies.length > 0) {
            return {
              ...comment,
              replies: await updateComments (comment.replies),
            };
          }
          return comment;
        })
      );
      return updatedComments;
    };

    const updatedComments = await updateComments (comments);
    setComments (updatedComments);
  };

  // Función recursiva para actualizar los "me gusta" en los comentarios anidados
  const updateLikes = (replies, id, statusCode) => {
    return replies.map (reply => {
      if (reply.id === id) {
        const newLikes = statusCode === 'comment_like'
          ? reply.likes + 1
          : reply.likes - 1;
        return {...reply, likes: newLikes};
      } else if (reply.replies && reply.replies.length > 0) {
        return {...reply, replies: updateLikes (reply.replies, id, statusCode)};
      }
      return reply;
    });
  };

  const handleLike = async id => {
    try {
      // Realiza la solicitud de "me gusta"
      const status = await CommentSystemPackLike ('POST', id);
      const updatedComments = comments.map (comment => {
        if (comment.id === id) {
          // Si el comentario tiene el ID que buscamos, actualiza los "me gusta"
          const newLikes = status.status_code === 'comment_like'
            ? comment.likes + 1
            : comment.likes - 1;
          return {...comment, likes: newLikes};
        } else if (comment.replies && comment.replies.length > 0) {
          // Si el comentario tiene respuestas, recursivamente actualiza los "me gusta"
          return {
            ...comment,
            replies: updateLikes (comment.replies, id, status.status_code),
          };
        }
        return comment;
      });

      setComments (updatedComments);
    } catch (error) {
      console.error ('Error liking comment:', error);
    }
  };

  return (
    <Card>
      {comments && comments.length > 0
        ? comments.map (comment => (
            <Comment
              key={comment.id}
              comment={comment}
              onReply={handleReply}
              onLike={handleLike}
            />
          ))
        : <p className="notComment">No hay comentarios</p>}
      <form onSubmit={handleSubmit} className="new-comment-form">

        <Avatar src={``} alt="Usuario Actual" />
        <div style={{flex: 1, marginLeft: '12px', marginRight: '12px'}}>
          <Input
            value={newComment}
            onChange={e => setNewComment (e.target.value)}
            placeholder="Escribe un comentario..."
          />
        </div>
        <Button type="submit">
          <Send />
        </Button>
      </form>
    </Card>
  );
};

export default CommentSystem;
