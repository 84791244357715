import React from "react";
import "./header.css";

export default function Header(props) {
  const { title, left, right } = props;
  return (
    <div className="app-header">
      <div className="app-header-left">{left}</div>
      <div className="app-header-title">{title}</div>
      <div className="app-header-right">{right}</div>
    </div>
  );
}
