export function isAudio(filename) {
    if (!filename) {
        return false;
    }
    const audioExtensions = ["mp3", "wav", "ogg"]; // Add more audio extensions if needed
    const extension = filename.split(".").pop().toLowerCase();
    return audioExtensions.includes(extension);
}

export function isVideo(url) {
    if (!url) {
        return false;
    }

    // Verificar si la URL contiene una extensión de video directa
    const directExtensionRegex = /\.(mp4|webm|ogg)$/i;
    if (directExtensionRegex.test(url)) {
        return true;
    }

    // Verificar si la URL es una URL firmada de Amazon S3
    const signedUrlRegex = /\.(mp4|webm|ogg)(\?.*)$/i;
    if (signedUrlRegex.test(url)) {
        return true;
    }

    return false;
}


export function isImage(url) {
    if (!url) {
        return false;
    }

    // Verificar si la URL contiene una extensión de imagen directa
    const directExtensionRegex = /\.(jpg|jpeg|png)$/i;
    if (directExtensionRegex.test(url)) {
        return true;
    }
    
    
    // Verificar si la URL es una URL firmada de Amazon S3
    const signedUrlRegex = /\.(jpg|jpeg|png|gif|bmp|svg)(\?.*)$/i;
    
    if (signedUrlRegex.test(url)) {
        return true;
    }

    return false;
}