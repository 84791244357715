import React, { useState, useEffect, useCallback } from "react";
import "./offer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEarthAmericas,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import PrincipalTemplate from "../commons/template/principalTemplate";
import { t } from "i18next";
import { OfferService } from "./offer.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import OffertItem from "./offertItem";
import OfferForm from "./offert-form";
import { useLogin } from "../../hooks/useLogin";

export default function Offer() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getUrlPage } = useLogin();
  const [openMenu, setOpenMenu] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const getData = useCallback(async () => {
    try {
      const data = await OfferService("GET");
      setDataList(data);
    } catch (error) {
      if (error.message === "exit") {
        navigate("/");
      } else if (error) {
        setErrorMessages(error);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      getData();
    })();
  }, []);

  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu(true);
        }}
      />
    );
  };
  const rightButton = () => {
    return (
      <FontAwesomeIcon
        icon={faEarthAmericas}
        className="icon-facebook"
        onClick={() => {
          window.open(getUrlPage());
        }}
      />
    );
  };

  return (
    <PrincipalTemplate
      headerLeft={leftButton()}
      headerRight={rightButton()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t("offers")}
      footerActive="offer"
    >
      <div className="offer-btn-plus">
        <FontAwesomeIcon
          icon={faCirclePlus}
          className="icon-plush"
          onClick={() => {
            setShowModal(true);
          }}
        />
      </div>
      <div> sdafasdf</div>

       <OfferForm
        value={{ name: "", description: "" }}
        showModal={showModal}
        setShowModal={setShowModal}
        onChange={(item) => {
          setDataList([item, ...dataList]);
        }}
      /> 

      <div className="container-offer">
        {dataList &&
          dataList.map((item, index) => {
            return <OffertItem key={index} data={item} />;
          })}
      </div> 
      <br />
      <br />
      <br />
      <br />
    </PrincipalTemplate>
  );
}
