import React from "react";
import "./message.css";
import { TYPE_MESSAGE } from "../../../utils/Constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faXmarkCircle,
} from "@fortawesome/free-regular-svg-icons";

import {
  faTriangleExclamation,
  faBug,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";

const Message = (props, ref) => {
  const { type, title, messageText, show, onClose } = props;

  const getStyleType = () => {
    switch (type) {
      case TYPE_MESSAGE.SUCCESS:
        return "message-success";
      case TYPE_MESSAGE.ERROR:
        return "message-error";
      case TYPE_MESSAGE.WARNING:
        return "message-warning";
      default:
        return "message-info";
    }
  };

  const getIconType = () => {
    switch (type) {
      case TYPE_MESSAGE.SUCCESS:
        return (
          <FontAwesomeIcon icon={faCheckCircle} className="icon-message " />
        );
      case TYPE_MESSAGE.ERROR:
        return <FontAwesomeIcon icon={faBug} className="icon-message " />;
      case TYPE_MESSAGE.WARNING:
        return (
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="icon-message "
          />
        );
      default:
        return (
          <FontAwesomeIcon icon={faInfoCircle} className="icon-message " />
        );
    }
  };
  return (
    show && (
      <div className={["message-container " + getStyleType()]} r>
        <div className="message-icon">{getIconType()}</div>
        <div className="message-body">
          <h3>{title}</h3>
          <p>{messageText}</p>
        </div>
        <div className="message-close">
          <FontAwesomeIcon
            icon={faXmarkCircle}
            className="icon-message "
            onClick={onClose}
          />
        </div>
      </div>
    )
  );
};

export default Message;
