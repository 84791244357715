import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "./createAccount.css";
import { useRegistration } from "../../../hooks/useRegistration";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getListError } from "../../../utils/Utils";
import { useParams, useNavigate, useLocation } from "react-router-dom";

export default function CreateAccount(props) {
  const { isLogged, user, error, registration } = useRegistration();
  const [errorLogin, setErrorLogin] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (error) {
      setErrorLogin(getListError(error));
    }
  }, [error]);

  if (isLogged) {
    navigate("/perfil");
  }

  const formik = useFormik({
    initialValues: {
      username: "",
      email: "",
      password1: "",
      password2: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(1, "Minimo 1 una letra")
        .max(20, "Maximo 20 letras")
        .required("Nombre de usuario es requerido"),
      email: Yup.string()
        .email("Email es inválido")
        .required("Email es requirido"),
      password1: Yup.string()
        .min(8, "Debe ser mayor a 8 caracteres")
        .required("Debe ingresar una contraseña"),
      password2: Yup.string()
        .min(8, "Debe ser mayor a 8 caracteres")
        .required("Debe ingresar una contraseña"),
    }),
    onSubmit: async (values) => {
      setErrorLogin("");
      // await registration(
      //   values.username,
      //   values.email,
      //   values.password1,
      //   values.password2
      // );

      /*if (data && data.non_field_errors) {
          setErrorLogin(data.non_field_errors[0]);
        }
        */
      // alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      {/*<div className="close">
        <FontAwesomeIcon
          icon={faClose}
          className="icon-facebook"
          onClick={() => props.close()}
        />
      </div> */}
      <div className="input-data">
        <span>Nombre de usuario</span>
        <input
          id="username"
          type="text"
          name="username"
          onChange={formik.handleChange}
          value={formik.values.username}
        />
        {formik.touched.username && formik.errors.username ? (
          <div className="error">{formik.errors.username}</div>
        ) : null}
      </div>

      <div className="input-data">
        <span>Dirección email</span>
        <input
          id="email"
          type="email"
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error">{formik.errors.email}</div>
        ) : null}
      </div>

      <div className="input-data">
        <span>Escribe tu contraseña</span>
        <input
          id="password1"
          type="password"
          name="password1"
          onChange={formik.handleChange}
          value={formik.values.password1}
        />
        {formik.touched.password1 && formik.errors.password1 ? (
          <div className="error">{formik.errors.password1}</div>
        ) : null}
      </div>

      <div className="input-data">
        <span>Confirmar contraseña</span>
        <input
          id="password2"
          type="password"
          name="password2"
          onChange={formik.handleChange}
          value={formik.values.password2}
        />

        {formik.touched.password2 && formik.errors.password2 ? (
          <div className="error">{formik.errors.password2}</div>
        ) : null}
      </div>

      <div className="input-data">
        <button className="btn-login" type="submit">
          Registrar
        </button>
        {errorLogin &&
          errorLogin.length &&
          errorLogin.map((err, index) => {
            return (
              <div className="error" key={"err_" + index}>
                {err}
              </div>
            );
          })}
      </div>
    </form>
  );
}
