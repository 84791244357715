import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./css/index.css";
import "./css/constants.css";
import AxiosInterceptors from "./api/AxiosInterceptors"; // TODO no eliminar, inserta los intersectores
import Login from "./routers/LoginRouter";
import Perfil from "./routers/PerfilRouter";
import OfferRouter from "./routers/OfferRouter";
import PackRouter from "./routers/PackRouter";
import GaleryRouter from "./routers/GaleryRouter";
import PageRouter from "./routers/PageRouter";
import PaymentMethodsRouter from "./routers/PaymentMethodsRouter";
import ContactInformationRouter from "./routers/ContactInformationRouter";
import MultimediaRouter from "./routers/MultimediaRouter";
import "./location/i18n";
import reportWebVitals from "./reportWebVitals";
import ChallengeRouter from "./routers/ChallengeRouter";
import HistoryWindowRouter from "./routers/HistoryWindowRouter";
import SalesHistoryRouter from "./routers/SalesHistoryRouter";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/perfil" element={<Perfil />} />
      <Route path="/offer" element={<OfferRouter />} />
      <Route path="/pack" element={<PackRouter />} />
      <Route path="/galery" element={<GaleryRouter />} />
      <Route path="/sales-history" element={<SalesHistoryRouter />} />
      <Route path="/page" element={<PageRouter />} />
      <Route path="/payment-method" element={<PaymentMethodsRouter />} />
      <Route path="/multimedia/:id" element={<MultimediaRouter />} />
      <Route path="/challenge" element={<ChallengeRouter />} />
      <Route path="/history-window/:id" element={<HistoryWindowRouter />} />
      <Route path="/contact-information" element={<ContactInformationRouter />}/>
      <Route path="*" element={<div>404</div>} />
    </Routes>
  </BrowserRouter>
);
//</React.StrictMode>

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
