import React, { useState, useEffect, useCallback } from "react";
import "./offer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faPenToSquare,
  faCircleCheck,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import createDOMPurify from "dompurify";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextareaRich from "../html/textareaRich/textareaRich";
import Modal from "../commons/modal/modal";
import OfferForm from "./offert-form";

export default function OffetItem(props) {
  const { data, edit } = props;
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [valueEdit, setValueEdit] = useState({});
  return (
    <div className="container-offer-item">
      <OfferForm
        isEdit={true}
        value={valueEdit}
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        onChange={(item) => {}}
      />

      <div className="container-offer-title">{data.name}</div>
      <div className="container-offer-content">
        {
          <div
            dangerouslySetInnerHTML={{
              __html: createDOMPurify.sanitize(data.description),
            }}
          />
        }
      </div>
      <div className="container-offer-action">
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="icon-facebook"
          onClick={() => {}}
        />
        <FontAwesomeIcon
          icon={faPenToSquare}
          className="icon-facebook"
          onClick={() => {
            setValueEdit(data);
            setShowModalEdit(true);
          }}
        />
      </div>
    </div>
  );
}
