import React, { useState } from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faClose } from "@fortawesome/free-solid-svg-icons";
import { useLogin } from "../../../hooks/useLogin";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./signEmail.css";
import { useNavigate, useLocation } from "react-router-dom";

export default function SignEmail(props) {
  const { login, error, setError} = useLogin();
  const [errorLogin, setErrorLogin] = useState("");
  const navigate = useNavigate();
  //const location = useLocation();

  const formik = useFormik({
    initialValues: initialValuesData() /* {
      username: "",
      password: "",
    }*/,
    validationSchema: validationSchemaData() /*Yup.object({
      username: Yup.string()
        .max(20, "Debe ser menor a 20 caracteres")
        .required("Debe ingresar el nombre de usuario"),
      password: Yup.string()
        .min(6, "Debe ser mayor a 6 caracteres")
        .required("Debe ingresar una contraseña"),
    })*/,
    onSubmit: async (values) => {
      try {
        setErrorLogin("");
        const logger = await login(values.username, values.password);
        //alert(logger);
        if (logger) {
          navigate("/pack");
        }
      } catch (error) {
        setErrorLogin(error.message);
      }

      //  if (data && data.non_field_errors) {
      //    setErrorLogin(data.non_field_errors[0]);
      //  }
      // alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      {/* <div className="close">
        <FontAwesomeIcon
          icon={faClose}
          className="icon-facebook"
          onClick={() => props.close()}
        /> 
      </div>
        */}
      <div className="input-data">
        <span>Nombre de usuario</span>
        <input
          id="username"
          type="username"
          name="username"
          onChange={formik.handleChange}
          onFocus={() => {setError(null)}}
          value={formik.values.username}
        />
        {formik.touched.username && formik.errors.username ? (
          <div className="error">{formik.errors.username}</div>
        ) : null}
      </div>

      <div className="input-data">
        <span>Contraseña</span>
        <input
          id="password"
          type="password"
          name="password"
          onChange={formik.handleChange}
          onFocus={() => {setError(null)}}
          value={formik.values.password}
        />
        {formik.touched.password && formik.errors.password ? (
          <div className="error">{formik.errors.password}</div>
        ) : null}
      </div>

      <div className="input-data">
        <button className="btn-login" type="submit" id ="step1-target">
          Iniciar sesión
        </button>

        <div className="error">
          {error} {errorLogin}
        <br/>
        <br/>
        </div>

        <p>Si tienes dudas o inquietudes puedes comunicarte a <strong>info@contenidohot.com</strong></p>
      </div>
    </form>
  );
}

function validationSchemaData() {
  return Yup.object({
    username: Yup.string()
      .max(20, "Debe ser menor a 20 caracteres")
      .required("Debe ingresar el nombre de usuario"),
    password: Yup.string()
      .min(6, "Debe ser mayor a 6 caracteres")
      .required("Debe ingresar una contraseña"),
  });
}

function initialValuesData() {
  return {
    username: "",
    password: "",
  };
}
