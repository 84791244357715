import React, { useState } from "react";
import { registration as registrationApi } from "../api/ServerApiAxios";
import { useLocalStorage } from "./useLocalStorage";
import { KEY_STORAGE } from "../utils/Constant";
import { getListError } from "../utils/Utils";

export const useRegistration = () => {
  const { setItem } = useLocalStorage();
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(null);
  const [error, setError] = useState(null);

  const registration = async (username, email, password1, password2) => {
    try {
      const res = await registrationApi(username, email, password1, password2);

      if (res.status === 400) {
        const listError = getListError(res.body);
        setError(listError);
      } else {
        setItem(KEY_STORAGE.USER_LOGIN, res);
        setItem(KEY_STORAGE.USER_TOKEN, res.access_token);
        setItem(KEY_STORAGE.USER_REFRESH_TOKEN, res.refresh_token);

        setUser(res);
        setIsLogged(true);
        setError(null);
      }
    } catch (error) {
      if (error.response.status == "400") {
        setError(error.response.data);
        //setError("Usuario o contraseña incorrectos");
      }
    }
  };

  return { isLogged, user, error, registration };
};
