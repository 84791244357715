import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faOpencart} from '@fortawesome/free-brands-svg-icons';
import {faCircleUser} from '@fortawesome/free-regular-svg-icons';
import {faAddressCard} from '@fortawesome/free-regular-svg-icons';

import './footer.css';

export default function Footer (props) {
  const [selected, setSelected] = useState ();
  const {active} = props;


  useEffect (
    () => {
      setSelected (active);
    },
    [active]
  );

  return (
    <div className="app-footer">

      <div
        className={
          active === 'perfil'
            ? 'app-footer-item select-item step-item-3'
            : 'app-footer-item step-item-3'
        }
      >
        <Link to="/perfil">
          <FontAwesomeIcon icon={faCircleUser} className="icon-facebook" />
        </Link>
      </div>
      <div
        className={
          active === 'pack'
            ? 'app-footer-item select-item step-item-2'
            : 'app-footer-item step-item-2'
        }
      >
        <Link to="/pack">
          <FontAwesomeIcon icon={faOpencart} className="icon-facebook " />
        </Link>
      </div>

      <div
        className={
          active === 'contact-information'
            ? 'app-footer-item select-item step-item-1'
            : 'app-footer-item step-item-1'
        }
      >
        <Link to="/contact-information">
          <FontAwesomeIcon icon={faAddressCard} className="icon-facebook " />
        </Link>
      </div>

      {/*<div
        className={
          active === "galery"
            ? "app-footer-item select-item"
            : "app-footer-item"
        }
      >
        <Link to="/galery">
          <FontAwesomeIcon icon={faImages} className="icon-facebook" />
        </Link>
      </div> */}
    </div>
  );
}
