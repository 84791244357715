import React, { useState, useCallback, useEffect } from "react";
import "./contactInformation.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import PrincipalTemplate from "../commons/template/principalTemplate";
import { useTranslation } from "react-i18next";
import Checkbox from "../html/checkbox/checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ContactInformationService } from "./contactInformation.service";
import { useNavigate } from "react-router-dom";
import { contactTypeService } from "../../api/ServerApiAxios";
import { useLogin } from "../../hooks/useLogin";
import {validationRules} from './validationRules'

export default function ContactInformation() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getUrlPage } = useLogin();
  const [openMenu, setOpenMenu] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");
  const [errorMessages, setErrorMessages] = useState([]);
  const [contactTypeList, setContactTypeList] = useState([]);
  const [listCheckbox, setListCheckbox] = useState([]);

  const [statusItemsList, setStatusItemsList] = useState([]);
  const formAddPayment = useFormik({
    initialValues: {
      contact: "",
      text: "",
    },
    validationSchema: Yup.object({
      contact: Yup.string().required("Seleccione un método de pago"),
      text: Yup.string().when("contact", (contactValue, schema) => {
        const rule = validationRules[contactValue];
        if (rule && rule.type === "domain") {
          return schema
            .required("Información requerida")
            .matches(rule.pattern, rule.errorMessage);
        } else if (rule && rule.type === "email") {
          // Add email validation logic here
        } else if (rule && rule.type === "phone") {
          // Add phone validation logic here
        }
        return schema.required("Información requerida");
      }),
    }),
    onSubmit: async (values) => {
      try {
        const dataItem = await ContactInformationService("POST", values);
        setListCheckbox([...listCheckbox, dataItem]);
        setStatusItemsList({
          ...statusItemsList,
          [dataItem.pk]: dataItem.show,
        });
        alert("Información creada correcctamente");
      } catch (error) {
        if (error.message === "exit") {
          navigate("/");
        } else {
          setErrorMessages(error);
        }
      }
    },
  });

  const getData = useCallback(async () => {
    try {
      const data = await ContactInformationService("GET");
      const contactTypeItem = await contactTypeService();
      setListCheckbox(data);
      setContactTypeList(contactTypeItem);
      setStatusItemsList(initialValues(data));
    } catch (error) {
      if (error.message === "exit") {
        navigate("/");
      } else if (error) {
        setErrorMessages(error);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      getData();
    })();
  }, []);

  const getNameContact = (id) => {
    const item = contactTypeList.find((item) => item.pk === id);
    return item?.name;
  };


  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu(true);
        }}
      />
    );
  };

  const rightButton = () => {
    return (
      <div>
        <FontAwesomeIcon
          icon={faEarthAmericas}
          className="icon-facebook"
          onClick={() => {
            //setOpenMenu(true);
            window.open(getUrlPage());
          }}
        />
      </div>
    );
  };

  return (
    <PrincipalTemplate
      headerLeft={leftButton()}
      headerRight={rightButton()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t("contactInformation")}
      footerActive="contact-information"
    >
      <div className={"container-payment-principal "}>
        <form onSubmit={formAddPayment.handleSubmit}>
          <div className="input-data">
            <span>{t("type")}</span>
            <select
              id="contact"
              name="contact"
              value={formAddPayment.values["contact"]}
              onChange={(event) => {
                formAddPayment.setFieldValue("contact", event.target.value);
              }}
            >
              <option></option>
              {contactTypeList &&
                contactTypeList.map((item) => {
                  return (
                    <option key={item.pk} value={item.pk}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="input-data">
            <span>{t("information")}</span>
            <input
              id="text"
              type="text"
              name="text"
              value={formAddPayment.values["text"]}
              onChange={(event) => {
                formAddPayment.setFieldValue("text", event.target.value);
              }}
            />

            {formAddPayment.errors.text && (
              <span className="error-message">{formAddPayment.errors.text}</span>
              )}
          </div>
          <div className="input-data">
            <button type="submit">{t("add")} </button>
            {formAddPayment.errors.paymentMethod}
            {formAddPayment.errors.description}
          </div>
        </form>
        <form className={listCheckbox && listCheckbox.length ? " " : "hidden"}>
          <div className="content-item-price-payment">
            <h3>{t("contactInformationPublished")}</h3>
            <div className="title-item-price-payment">
              <div></div>
              <div>{t("view")}</div>
            </div>
            {listCheckbox.map((item) => {
              return (
                <div className="item-price-payment" key={item.pk + "_con"}>
                  <div className="item-description">
                    <img 
                    className="item-logo-socialmedia"
                      src={require(`../../assets/images/socialMedia/${item.contact_name}.png`)}
                      alt={item.contact}
                    />
                    <div className="container-type-information">
                      <span>
                        {item && item.contact
                          ? getNameContact(item.contact)
                          : ""}
                      </span>
                      <p>{item.text}</p>
                    </div>
                  </div>
                  <div className="content-switch">
                    {item && item.pk && (
                      <Checkbox
                        name={item.pk}
                        id={item.pk}
                        value={statusItemsList[item.pk]}
                        extra={item}
                        formikSetFieldValue={async (key, value, item) => {
                          try {
                            const dataItem = await ContactInformationService(
                              "PUT",
                              {
                                text: item.text,
                                contact: item.contact,
                                pk: key,
                                show: value,
                              }
                            );
                            setStatusItemsList({
                              ...statusItemsList,
                              [key]: value,
                            });
                            alert("Información actualizada");
                          } catch (error) {
                            if (error.message === "exit") {
                              navigate("/");
                            } else {
                              setErrorMessages(error);
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      </div>
    </PrincipalTemplate>
  );
}

const initialValues = (list) => {
  const data = {};
  list.map((item) => {
    data[item.pk] = item.show;
  });
  return data;
};
