import imageCreator from '../../../assets/images/images/creator_2.png'
import './watermark.css'
export default function Watermark({ message, action, btnText }) {

const watermarkImage = (
    <img
      src={imageCreator}
      alt="Watermark"
      className="watermark-opacity"
    />
  );

  const noDataList = (
    <div className="no-found-multimedia">
      {watermarkImage}
      <h2 className='title-watermark'> {message ? message: "No hay Archivos"}</h2>
      <br />

      <button className='btn-action' onClick={action} >{ btnText ? btnText : "Crear"}</button>
      
    </div>)

return noDataList
}