import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faOpencart } from "@fortawesome/free-brands-svg-icons";
import { faImages, faCircleUser } from "@fortawesome/free-regular-svg-icons";
import {
  faLanguage,
  faFile,
  faFileInvoiceDollar,
  faArrowRightFromBracket,
  faCommentsDollar,
  faTrophy,
  faFileInvoice
} from "@fortawesome/free-solid-svg-icons";
import "./menu.css";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { useLogin } from "../../../hooks/useLogin";

export default function Menu(props) {
  const { t, i18n } = useTranslation();
  const { active, action } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const { login, error, isLogged, logout, user } = useLogin();

  return (
    <div className={active ? "menu-container open-menu " : "menu-container "}>
      <div onClick={() => action(false)} className="close-menu">
        X
      </div>

      <div className="item-list-menu">
        <Link to="/perfil">
          <FontAwesomeIcon icon={faCircleUser} className="icon-facebook" />
          {t("profile")}
        </Link>
      </div>
      {/* <div className="item-list-menu">
        <Link to="/galery">
          <FontAwesomeIcon icon={faImages} className="icon-facebook" />
          {t("galery")}
        </Link>
      </div>
      <div className="item-list-menu">
        <Link to="/offer">
          <FontAwesomeIcon icon={faOpencart} className="icon-facebook" />
          {t("offers")}
        </Link>
      </div> */}

      <div className="item-list-menu">
        <Link to="/pack">
          <FontAwesomeIcon icon={faOpencart} className="icon-facebook" />
          {t("packs")}
        </Link>
      </div>


      {/* TODO: permite configurar la url de pagina  
      
      <div className="item-list-menu">
        <Link to="/page">
          <FontAwesomeIcon icon={faFile} className="icon-facebook" />
          {t("page")}
        </Link>
      </div> */}
      <div className="item-list-menu">
        <Link to="/contact-information">
          <FontAwesomeIcon icon={faCommentsDollar} className="icon-facebook" />
          {t("contactInformation")}
        </Link>
      </div>
      <div className="item-list-menu">
        <Link to="/payment-method">
          <FontAwesomeIcon
            icon={faFileInvoiceDollar}
            className="icon-facebook"
          />
          {t("paymentMethod")}
        </Link>
      </div>
      <div className="item-list-menu">
        <Link to="/sales-history">
          <FontAwesomeIcon
            icon={faFileInvoice}
            className="icon-facebook"
          />
          {t("Historial de ventas")}
        </Link>
      </div>
      {/* TODO: se desactivas desafios
      
      <div className="item-list-menu">
        <Link to="/challenge">
          <FontAwesomeIcon
            icon={faTrophy}
            className="icon-facebook"
          />
          {t("Desafios")}
        </Link>
      </div> */}
      {/* TODO: Eso es para activar la traduccion 
      
      <div className="item-list-menu lang">
        <FontAwesomeIcon icon={faLanguage} className="icon-facebook" />{" "}
        {t("changeLanguage")}
        <div className="lang-item">
          <span
            onClick={() => {
              i18n.changeLanguage("es");
            }}
          >
            ES
          </span>
          <span
            onClick={() => {
              i18n.changeLanguage("en");
            }}
          >
            EN
          </span>
        </div>
      </div> */}
      <div className="item-list-menu">
        <Link to="/login">
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            className="icon-facebook"
            onClick={() => {
              logout();
              navigate("/login");
            }}
          />
          {t("exit")}
        </Link>
      </div>
    </div>
  );
}
