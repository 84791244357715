import { useState} from "react";
import { login as loginApi } from "../api/ServerApiAxios";
import { useLocalStorage } from "./useLocalStorage";
import { KEY_STORAGE, API_URL } from "../utils/Constant";

export const useLogin = () => {
  const { setItem, removeItem, getItem } = useLocalStorage();
  const [isLogged, setIsLogged] = useState(false);
  const [user, setUser] = useState(null);
  const [emailConfirmed, setEmailConfirmed] = useState(false);
  const [error, setError] = useState(null);
  
  const login = async (username, password) => {
    try {
      const res = await loginApi(username, password);
      setItem(KEY_STORAGE.USER_LOGIN, res);
      setItem(KEY_STORAGE.USER_TOKEN, res.access);
      setItem(KEY_STORAGE.USER_REFRESH_TOKEN, res.refresh);

      setUser(res);
      setIsLogged(true);
      setError(null);
      return true;
    } catch (error) {
      setError("Usuario o contraseña incorrectos");
      return false;
    }
  };

  const logout = () => {
    setUser(null);
    setIsLogged(false);

    removeItem(KEY_STORAGE.USER_LOGIN);
    removeItem(KEY_STORAGE.USER_TOKEN);
    removeItem(KEY_STORAGE.USER_REFRESH_TOKEN);
    removeItem(KEY_STORAGE.USER_PROFILE);
  };

  const getUser = () => {
    return getItem(KEY_STORAGE.USER_LOGIN);
  };

  const getProfile = () => {
    return getItem(KEY_STORAGE.USER_PROFILE);
  };

  const getUrlPage = () => {
    const profileData = getItem(KEY_STORAGE.USER_PROFILE);
    if (profileData) {
      return API_URL + "/" + profileData.slug;
    } else {
      return null;
    }
  };

  const validateEmailConfirmed = (status) => {
    setEmailConfirmed(status)
  }

  const getValidateEmailConfirmed = () => {
    return emailConfirmed;
  }


  return {
    isLogged,
    user,
    error,
    getValidateEmailConfirmed,
    login,
    setError,
    validateEmailConfirmed,
    getUrlPage,
    logout,
    getUser,
    getProfile
  };
};
