import React, { useState, useEffect, useCallback } from "react";
import "./profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faCamera,
  faEarthAmericas,
  faFloppyDisk,
} from "@fortawesome/free-solid-svg-icons";
import PrincipalTemplate from "../commons/template/principalTemplate";
import { useTranslation } from "react-i18next";
import { ProfileService } from "./profile.service";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import TextareaRich from "../html/textareaRich/textareaRich";
import MessageManager from "../commons/message/messageManager";
import { TYPE_MESSAGE, API_URL, KEY_STORAGE } from "../../utils/Constant";
import { useLogin } from "../../hooks/useLogin";
import { useLocalStorage } from "../../hooks/useLocalStorage";

export default function Profile() {
  const { setItem } = useLocalStorage();
  const navigate = useNavigate();
  const [selectedImageFoto, setSelectedImageFoto] = useState(null);
  const [selectedImageFrontPage, setSelectedImageFrontPage] = useState(null);
  const { t, i18n } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [messageManagerState, setMessageManagerState] = useState({
    show: false,
    type: TYPE_MESSAGE.INFO,
    title: "a",
    messageText: "b",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesData(profileData),
    validationSchema: validationSchemaData(t),
    onSubmit: async (values) => {
      try {
        await ProfileService("PUT", values, selectedImageFoto, selectedImageFrontPage);
        handleMessage(true, "", t("saveMessage"), TYPE_MESSAGE.SUCCESS);
      } catch (error) {
        if (error.message === "exit") {
          navigate("/");
        } else {
          //setErrorMessages(error);
          handleMessage(true, "", t("saveMessage"), TYPE_MESSAGE.ERROR);
        }
      }
    },
  });

  const handleMessage = (show, title, messageText, type) => {
    setMessageManagerState({
      show: show,
      type: type,
      title: title,
      messageText: messageText,
    });
    setTimeout(() => {
      setMessageManagerState({
        show: false,
        type: TYPE_MESSAGE.INFO,
        title: "",
        messageText: "",
      });
    }, 3000);
  };

  const getDataPerfil = useCallback(async () => {
    try {
      const data = await ProfileService("GET");
      setProfileData(data);
      setItem(KEY_STORAGE.USER_PROFILE, data);
    } catch (error) {
      if (error.message === "exit") {
        navigate("/");
      } else if (error) {
        setErrorMessages(error);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      getDataPerfil();
    })();
  }, []);

  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu(true);
        }}
      />
    );
  };

  const rightButton = () => {
    return (
      <div>
        <FontAwesomeIcon
          icon={faFloppyDisk}
          className="icon-facebook"
          onClick={() => {
            formik.submitForm();
          }}
        />
        <FontAwesomeIcon
          icon={faEarthAmericas}
          className="icon-facebook"
          onClick={() => {
            const data = profileData;
            window.open(API_URL + "/" + data.slug);
          }}
        />
      </div>
    );
  };

  return (
    <PrincipalTemplate
      headerLeft={leftButton()}
      headerRight={rightButton()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t("profile")}
      footerActive="perfil"
      emailConfirmed={profileData.email_confirmed}
    >
      <form onSubmit={formik.handleSubmit}>
        <MessageManager
          show={messageManagerState.show}
          title={messageManagerState.title}
          messageText={messageManagerState.messageText}
          type={messageManagerState.type}
          onClose={() => {
            setMessageManagerState({
              show: false,
              type: TYPE_MESSAGE.INFO,
              title: "a",
              messageText: "b",
            });
          }}
        />
        <div className="container-profile-principal">
          <div className="profile-item-imagen-select-item">
            <span>Perfil</span>
            <input
              id="imageUpload"
              type="file"
              name="imageUpload"
              value={formik.values.imageUpload}
              onChange={(event) => {
                formik.handleChange(event);
                setSelectedImageFoto(event.target.files[0]);
              }}
            />
            <label for="imageUpload" className="profile-container-label-image-item2">
              {(!selectedImageFoto && !formik.values.foto) && (
                <div className="profile-img profile-item-image ">
                  <FontAwesomeIcon icon={faCamera} className="profile-images2" />
                  <br />
                  <div>{t("Cargar foto de perfil")} </div>{" "}
                </div>
              )}
              {(selectedImageFoto || formik.values.foto) && (
                <>
                  <img
                    alt="not fount"
                    className="profile-img"
                    src={
                      formik.values.foto ||
                      profileData.foto ||
                      URL.createObjectURL(selectedImageFoto)
                    }
                  />

                </>
              )}
            </label>
          </div>

          <div className="profile-item-imagen-select-item">
            <span>Portada</span>
            <input
              id="frontPage"
              type="file"
              name="frontPage"
              value={formik.values.frontPage}
              onChange={(event) => {
                formik.handleChange(event);
                setSelectedImageFrontPage(event.target.files[0]);
              }}
            />
            <label for="frontPage" className="profile-container-label-image-item">
              {(!selectedImageFrontPage && !formik.values.front_page) && (
                <>
                  <FontAwesomeIcon icon={faCamera} className="profile-images2" />
                  <br />
                  <div className="text-upload-file">{t("Cargar foto de portada")} </div>{" "}
                </>
              )}
              {(selectedImageFrontPage || formik.values.front_page) && (
                <img
                  alt="not fount"
                  className="profile-img-front-page"
                  src={
                    formik.values.front_page ||
                    profileData.front_page ||
                    URL.createObjectURL(selectedImageFrontPage)
                  }
                />
              )}
            </label>
          </div>




          <div className="input-data">
            <span>{t("userName")}</span>
            <input
              id="userPublic"
              name="userPublic"
              onChange={formik.handleChange}
              value={formik.values.userPublic}
              type="text"
            />
            {formik.errors.userPublic ? (
              <div className="error">{formik.errors.userPublic}</div>
            ) : (
              <div className="error"></div>
            )}
          </div>

          <div className="input-data">
            <span>{t("name")}</span>
            <input
              id="namePublic"
              name="namePublic"
              onChange={formik.handleChange}
              value={formik.values.namePublic}
              type="text"
            />
            {formik.errors.namePublic ? (
              <div className="error">{formik.errors.namePublic}</div>
            ) : (
              <div className="error"></div>
            )}
          </div>

          <div className="input-data">
            <span>{t("age")}</span>
            <input
              id="age"
              type="number"
              name="age"
              onChange={formik.handleChange}
              value={formik.values.age}
            />
            {formik.errors.age ? (
              <div className="error">{formik.errors.age}</div>
            ) : (
              <div className="error"></div>
            )}
          </div>


          <div className="input-checkbox">
            <input type='checkbox' name='isAnonymous' id="isAnonymous"
              checked={formik.values.isAnonymous}
              onChange={(event) => {
                formik.setFieldValue('isAnonymous', event.currentTarget.checked);
              }}
            />
            <label for='isAnonymous'>Soy timid@ </label>
          </div>

          <div className="input-checkbox">
            <input type='checkbox' name='video_calls' id="video_calls"
              checked={formik.values.video_calls}
              onChange={(event) => {
                formik.setFieldValue('video_calls', event.currentTarget.checked);
              }}
            />
            <label for='video_calls'>Realizo video llamadas </label>
          </div>



          <div className="input-data">
            <span>{t("description")} </span>
            <TextareaRich
              id="description"
              name="description"
              value={profileData.description}
              onChange={(a, value, c, dataInitial) => {
                formik.setFieldValue("description", value);
              }}
            />
            {formik.errors.description ? (
              <div className="error">{formik.errors.description}</div>
            ) : (
              <div className="error"></div>
            )}
          </div>
        </div>
      </form>
    </PrincipalTemplate>
  );
}

function initialValuesData(dataInitial) {
  return {
    userPublic: dataInitial && dataInitial.userPublic ? dataInitial.userPublic : "",
    namePublic: dataInitial && dataInitial.namePublic ? dataInitial.namePublic : "",
    age: dataInitial && dataInitial.age !== null ? String(dataInitial.age) : "", // Ensure age is not null
    message: dataInitial ? dataInitial.message : "",
    description: dataInitial ? dataInitial.description : "",
    foto: dataInitial && dataInitial.foto ? dataInitial.foto : "",
    front_page: dataInitial && dataInitial.front_page ? dataInitial.front_page : "",
    imageUpload: "",
    frontPage: "",
    video_calls: dataInitial && dataInitial.hasOwnProperty('video_calls') ? dataInitial.video_calls : false,
    isAnonymous: dataInitial && dataInitial.hasOwnProperty('isAnonymous') ? dataInitial.isAnonymous : false
  };
}

function validationSchemaData(t) {
  return Yup.object({
    userPublic: Yup.string()
      .max(30, t("messageValidation1"))
      .required("Debe ingresar el nombre de usuario"),
    namePublic: Yup.string()
      .max(30, t("messageValidation1"))
      .required("Debe ingresar el nombre de usuario"),
    age: Yup.string().nullable().required("Debe ingresar la edad"), // Make age nullable
    message: Yup.string().max(500, "Debe ser menor a 500 caracteres"),
    description: Yup.string().max(500, "Debe ser menor a 500 caracteres"),
    imageUpload: Yup.mixed(),
    frontPage: Yup.mixed(),
    video_calls: Yup.boolean(),
    isAnonymous: Yup.boolean(),
  });
}
