import React, { useState } from "react";
import "./challengeItem.css";
import { differenceInMinutes, differenceInHours, differenceInDays, differenceInMonths } from 'date-fns';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faCoins} from "@fortawesome/free-solid-svg-icons";

export default function ChallengeItem(props) {
    const { data, edit } = props;

    function formatDate(dateStr) {
        const date = new Date(dateStr);
        const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        return `${date.getDate()} ${months[date.getMonth()]} ${date.getFullYear()}`;
    }

    function getRemainingTime(expirationDateStr) {
        const now = new Date();
        const expirationDate = new Date(expirationDateStr);

        const diffInMinutes = differenceInMinutes(expirationDate, now);
        const diffInHours = differenceInHours(expirationDate, now);
        const diffInDays = differenceInDays(expirationDate, now);
        const diffInMonths = differenceInMonths(expirationDate, now);

        if (diffInMinutes <= 60) {
            return `Expira en ${diffInMinutes} minuto(s)`;
        }
        if (diffInHours < 24) {
            return `Expira en ${diffInHours} hora(s)`;
        }
        if (diffInDays < 30) {
            return `Expira en ${diffInDays} día(s)`;
        }
        return `Expira en ${diffInMonths} mes(es)`;
    }



    return (
        <div className="challenger-container">
            <div className="item-challenge-sub">
                <div>
                    #{data.category_name}
                </div>
                <div>
                    <span className="title-sub-challenge">Se te pagara:</span>
                    <FontAwesomeIcon
                        icon={faCoins}
                        className="coin-sub-challenge"
                        onClick={() => {
                            //  setShowModal(true);
                        }}
                    />
                    <span className="price-challege">
                    {data.amount}

                    </span>
                </div>
            </div>
            <div className="item-challenge-sub">
                <div>
                    <img src={"http://localhost:8001" + data.category_photo} className="img-category-challe" />
                </div>
                <div className="challenge-descripton">
                    {data.description_1}
                </div>
            </div>
            <div className="item-challenge-sub">
                <div></div>
                <div>  {getRemainingTime(data.expiration_date)}</div>
            </div>
            <div className="item-challenge-sub-center">
                <button className="btn-action">Aplicar a este desafío </button>
            </div>
        </div>
    );
}
