import React, { useState, useEffect, useCallback } from "react";
import "./salesHistory.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
} from "@fortawesome/free-solid-svg-icons";
import PrincipalTemplate from "../commons/template/principalTemplate";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SalesHistoryService } from "./salesHistory.service";
import { Link } from "react-router-dom";
import { format } from 'date-fns';
import { HTTP_METHOD } from "../../utils/Constant";

export default function SalesHistory({ id, pack }) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [openMenu, setOpenMenu] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);
    const [listSaleHistory, setListSaleHistory] = useState([]);

    const getSaleHistory = useCallback(async () => {
        try {
            const data = await SalesHistoryService(HTTP_METHOD.GET);
            setListSaleHistory(data);
        } catch (error) {
            if (error.message === "exit") {
                navigate("/");
            } else if (error) {
                setErrorMessages(error);
            }
        }
    }, []);

    useEffect(() => {
        (async () => {
            getSaleHistory()
        })();
    }, []);

    const leftButton = () => {
        return (
            <FontAwesomeIcon
                icon={faBars}
                className="icon-facebook step-item-4"
                onClick={() => {
                    setOpenMenu(true);
                }}
            />
        );
    };

    return (
        <PrincipalTemplate
            headerLeft={leftButton()}
            menuAction={setOpenMenu}
            menuActive={openMenu}
            headerTitle={t("")}
            footerActive="perfil"
        >
            <div className="container-history-all">
                <h3>Historial de Ventas </h3>
                {
                    listSaleHistory && listSaleHistory.length > 0 && listSaleHistory.map(item => {
                        const formattedDate = format(new Date(item.created), 'dd-MM-yyyy');

                        return <div className="container-item-sale">
                            <div className="sub-item-sale">
                                <Link to={`/multimedia/${item.pack_multimedia}`}>
                                    {item.slug_pack}
                                </Link>
                            </div>
                            <div className="sub-item-sale">
                               
                           
                            </div>
                            <div className="sub-item-sale">
                                <span>
                                    Fichas <span className="sale-price">{item.total_tokens}</span>
                                </span>
                                {item.paid ? <span className="sale-payment">Pagado</span> : <span className="sale-pending">Pendiente por pagar</span>}
                            </div>
                            <div className="sub-item-sale">
                                <span>
                                    COP <span className="sale-price">{item.total_payment_to_creator}</span>
                                </span>

                                <span className="sale-date">
                                    {formattedDate}
                                </span>
                            </div>
                        </div>
                    })
                }
                {listSaleHistory && listSaleHistory.length == 0 && (<p className="not-sales-pack"> No tienes ventas realizadas</p>)}
            </div>
        </PrincipalTemplate>
    );
}

