import React, { useState, useCallback, useEffect } from "react";
import * as Yup from "yup";
import "./paymentMethod.css";
import { useFormik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import PrincipalTemplate from "../commons/template/principalTemplate";
import Checkbox from "../html/checkbox/checkbox";
import Information from "../commons/information/information";
import { PaymentMethodService } from "./paymentMethod.service";
import { paymentMethodService as paymemtList } from "../../api/ServerApiAxios";
import { KEY_STORAGE_INFORMATION } from "../../utils/Constant";
import { useLogin } from "../../hooks/useLogin";
import criptomonedas from "../../assets/images/paymentMethod/criptomonedas.png";
import daviplata from "../../assets/images/paymentMethod/daviplata.png";
import nequi from "../../assets/images/paymentMethod/nequi.png";
import paypal from "../../assets/images/paymentMethod/paypal.png";
import tpaga from "../../assets/images/paymentMethod/tpaga.png";

export default function PaymentMethod() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getUrlPage } = useLogin();
  const [openMenu, setOpenMenu] = useState(false);
  const [errorLogin, setErrorLogin] = useState("");
  const [statusItemsList, setStatusItemsList] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [paymenthMethodTypeList, setPaymenthMethodTypeList] = useState([]);
  const [listCheckbox, setListCheckbox] = useState([]);

  const formAddPayment = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Seleccione un método de pago"),
    }),
    onSubmit: async (values) => {
      try {
        const dataItem = await PaymentMethodService("POST", values);
        setListCheckbox([...listCheckbox, dataItem]);
        setStatusItemsList({
          ...statusItemsList,
          [dataItem.pk]: dataItem.state,
        });
        alert("Medio de pago agregado");
      } catch (error) {
        if (error.message === "exit") {
          navigate("/");
        } else {
          setErrorMessages(error);
        }
      }
    },
  });

  const getData = useCallback(async () => {
    try {
      const data = await PaymentMethodService("GET");
      const contactTypeItem = await paymemtList();
      setListCheckbox(data);
      setPaymenthMethodTypeList(contactTypeItem);
      setStatusItemsList(initialValues(data));
    } catch (error) {
      if (error.message === "exit") {
        navigate("/");
      } else if (error) {
        setErrorMessages(error);
      }
    }
  }, []);

  useEffect(() => {
    (async () => {
      getData();
    })();
  }, []);

  const getNamePay = (id) => {
    const item = paymenthMethodTypeList.find((item) => item.pk === id);
    return item?.name;
  };

  const getLogoPayment = (id) => {
    const item = paymenthMethodTypeList.find((item) => item.pk === id);

    switch (item?.name) {
      case "paypal":
        return paypal;
      case "tpaga":
        return tpaga;
      case "daviplata":
        return daviplata;
      case "nequi":
        return nequi;
      case "criptomonedas":
        return criptomonedas;
      default:
        return "";
    }
  };
  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu(true);
        }}
      />
    );
  };

  const rightButton = () => {
    return (
      <div>
        <FontAwesomeIcon
          icon={faEarthAmericas}
          className="icon-facebook"
          onClick={() => {
            window.open(getUrlPage());
          }}
        />
      </div>
    );
  };

  return (
    <PrincipalTemplate
      headerLeft={leftButton()}
      headerRight={rightButton()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t("paymentMethod")}
      footerActive=""
    >
      <div className={"container-payment-principal "}>
        <Information
          title={t("remember")}
          keyStorage={KEY_STORAGE_INFORMATION.PAYMENT_METHODS}
        >
          {t("messagePaymentInfo")}
        </Information>
        <form onSubmit={formAddPayment.handleSubmit}>
          <div className="input-data">
            <span>{t("paymentMethod")}</span>
            <select
              id="name"
              name="name"
              value={formAddPayment.values["name"]}
              onChange={(event) => {
                formAddPayment.setFieldValue("name", event.target.value);
              }}
            >
              <option></option>
              {paymenthMethodTypeList &&
                paymenthMethodTypeList.map((item) => {
                  return (
                    <option key={item.pk} value={item.pk}>
                      {item.name}
                    </option>
                  );
                })}
            </select>
          </div>
          <div className="input-data">
            <button type="submit">{t("add")} </button>
            {formAddPayment.errors.paymentMethod}
            {formAddPayment.errors.description}
          </div>
        </form>
        <form className={listCheckbox && listCheckbox.length ? " " : "hidden"}>
          <div className="content-item-price-payment">
            <h3>{t("paymentMethodsPublished")}</h3>
            <div className="title-item-price-payment">
              <div></div>
              <div>{t("view")}</div>
            </div>
            {listCheckbox.map((item) => {
              return (
                <div className="item-price-payment" key={item.pk + "_con"}>
                  <div className="item-description">
                    <img src={getLogoPayment(item.name)} alt={item.name} />
                    <p>{item && item.name ? getNamePay(item.name) : ""}</p>
                  </div>
                  <div className="content-switch">
                    {item && item.pk && (
                      <Checkbox
                        name={item.pk}
                        id={item.pk}
                        value={statusItemsList[item.pk]}
                        extra={item}
                        formikSetFieldValue={async (key, value, item) => {
                          try {
                            const dataItem = await PaymentMethodService("PUT", {
                              name: item.name,
                              pk: key,
                              state: value,
                            });
                            setStatusItemsList({
                              ...statusItemsList,
                              [key]: value,
                            });
                            alert("Datos modificados");
                          } catch (error) {
                            if (error.message === "exit") {
                              navigate("/");
                            } else {
                              setErrorMessages(error);
                            }
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </form>
      </div>
    </PrincipalTemplate>
  );
}

const initialValues = (list) => {
  const data = {};
  list.map((item) => {
    data[item.pk] = item.state;
  });
  return data;
};
