import React, {useState, useEffect, useCallback, useRef} from 'react';
import './packItem.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFile, faPenToSquare} from '@fortawesome/free-solid-svg-icons';
import {PackService} from '../pack.service';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import TextareaRich from '../../html/textareaRich/textareaRich';
import Modal from '../../commons/modal/modal';
import MessageManager from '../../commons/message/messageManager';
import {TYPE_MESSAGE} from '../../../utils/Constant';
import {KEY_STORAGE} from '../../../utils/Constant';
import {useLocalStorage} from '../../../hooks/useLocalStorage';
import {formatDate} from '../../../utils/DateUtils';
import {isImage, isVideo} from '../../../utils/ValidationTypeFile';

const PackForm = props => {
  const {showModal, setShowModal, onChange, isEdit, value} = props;
  const {getItem} = useLocalStorage ();
  const navigate = useNavigate ();
  const {t} = useTranslation ();
  const [errorMessages, setErrorMessages] = useState ([]);
  const [selectedImageFrontPage, setSelectedImageFrontPage] = useState (null);
  const [profileUser, setProfileUser] = useState ();
  const [amountOriginal, setAmountOriginal] = useState ();
  const [uploadFile, setUploadFile] = useState (false);
  const frontPageURL = useRef ('');

  const [messageManagerState, setMessageManagerState] = useState ({
    show: false,
    type: TYPE_MESSAGE.INFO,
    title: 'a',
    messageText: 'b',
  });

  const statusValidationLabels = {
    A: 'Aprobado',
    P: 'Pendiente',
    R: 'Rechazado',
    M: 'Modificar',
  };

  useEffect (() => {
    if (isEdit) {
      const dataUpdate = {...value, name: value.front_page};
      frontPageURL.current = dataUpdate.front_page;
      setSelectedImageFrontPage (dataUpdate);
    }

    return () => {
      setSelectedImageFrontPage (null);
      setProfileUser (null);
      setAmountOriginal (null);
      setUploadFile (false);
    };
  }, []);

  const formik = useFormik ({
    enableReinitialize: true,
    initialValues: initialValuesData (value),
    validationSchema: Yup.object ({
      description: Yup.string (),
      price: Yup.number ().min (0, 'Debe ser mayor o igual a 0'),
      amount: Yup.number ().min (0),
      amount_original: Yup.number ().min (0),
      front_page: Yup.mixed ().required ('Imagen de portada requerida'),
      unlock_code: Yup.string (),
      show: Yup.boolean (),
    }),
    onSubmit: async values => {
      try {
        setUploadFile (true);
        values.unlock_code_active = true;
        if (isEdit) {
          value.price = values.price;
          value.amount = values.amount;
          value.description = values.description;
          value.front_page = selectedImageFrontPage;
          values.show = true;
          value.unlock_code = values.unlock_code;

          if (selectedImageFrontPage) {
            value.front_page = selectedImageFrontPage;
          }

          const dataItem = await PackService (
            'PUT',
            values,
            selectedImageFrontPage
          );
          onChange (dataItem);
          handleMessage (true, '', t ('saveMessage'), TYPE_MESSAGE.SUCCESS);
          setShowModal (false);
        } else {
          const referral_code = profileUser.referral_code;
          const profit_percentage = profileUser.profit_percentage;
          values.referral_code = referral_code;
          values.profit_percentage = profit_percentage;
          values.amount_original = amountOriginal || 0;
          const dataItem = await PackService (
            'POST',
            values,
            selectedImageFrontPage
          );
          onChange (dataItem);
          handleMessage (true, '', t ('saveMessage'), TYPE_MESSAGE.SUCCESS);
          setShowModal (false);
          alert ('Pack creado exitosamente.');

          if (profileUser.authorized_sell) {
            navigate ('/multimedia/' + dataItem.id);
          }
        }
        setUploadFile (false);
      } catch (error) {
        setUploadFile (false);
        console.log (error);
        if (error.message === 'exit') {
          navigate ('/');
        } else {
          setErrorMessages (error);
        }
      }
    },
  });

  const handleMessage = useCallback ((show, title, messageText, type) => {
    setMessageManagerState ({
      show: show,
      type: type,
      title: title,
      messageText: messageText,
    });
    setTimeout (() => {
      setMessageManagerState ({
        show: false,
        type: TYPE_MESSAGE.INFO,
        title: '',
        messageText: '',
      });
    }, 3000);
  }, []);

  useEffect (
    () => {
      if (showModal) {
        formik.resetForm ();
        if (!isEdit) {
          setSelectedImageFrontPage (null);
        }
      }
      (async () => {
        const dataProfile = await getItem (KEY_STORAGE.USER_PROFILE);
        setProfileUser (dataProfile);
      }) ();
    },
    [showModal]
  );

  useEffect (
    () => {
      if (selectedImageFrontPage) {
        if (typeof selectedImageFrontPage.front_page === 'string') {
          frontPageURL.current = selectedImageFrontPage.front_page;
        } else if (
          selectedImageFrontPage instanceof File ||
          selectedImageFrontPage instanceof Blob
        ) {
          frontPageURL.current = URL.createObjectURL (selectedImageFrontPage);
        } else if (
          selectedImageFrontPage.front_page instanceof File ||
          selectedImageFrontPage.front_page instanceof Blob
        ) {
          frontPageURL.current = URL.createObjectURL (
            selectedImageFrontPage.front_page
          );
        } else {
          console.error ('Invalid type for selectedImageFrontPage.front_page');
        }
      }
    },
    [selectedImageFrontPage]
  );

  const buildShowComponent = () => {
    return (
      <div>
        {selectedImageFrontPage &&
          isVideo (selectedImageFrontPage.name) &&
          <video
            controls
            className="profile-img-pack"
            key={frontPageURL.current}
          >
            <source src={frontPageURL.current} type="video/mp4" />
            Tu navegador no admite el elemento de video.
          </video>}
        {selectedImageFrontPage &&
          isImage (selectedImageFrontPage.name) &&
          <img
            alt="not found"
            className="profile-img-pack"
            src={frontPageURL.current}
            key={frontPageURL.current}
          />}
        {!selectedImageFrontPage &&
          isVideo (formik.values.front_page.name) &&
          <video
            controls
            className="profile-img-pack"
            key={formik.values.front_page}
          >
            <source src={formik.values.front_page} type="video/mp4" />
            Tu navegador no admite el elemento de video.
          </video>}
        {!selectedImageFrontPage &&
          isImage (formik.values.front_page.name) &&
          <img
            alt="not found"
            className="profile-img-pack"
            src={formik.values.front_page}
            key={formik.values.front_page}
          />}
        <label htmlFor="frontPage" className="edit-upload-file">
          <FontAwesomeIcon icon={faPenToSquare} className="edit-image-pack" />
        </label>
      </div>
    );
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MessageManager
        show={messageManagerState.show}
        title={messageManagerState.title}
        messageText={messageManagerState.messageText}
        type={messageManagerState.type}
        onClose={() => {
          setMessageManagerState ({
            show: false,
            type: TYPE_MESSAGE.INFO,
            title: 'a',
            messageText: 'b',
          });
        }}
      />
      {showModal &&
        <Modal
          footer={
            <div className="input-data offer-btn-add">
              {!uploadFile && <button type="submit">{t ('save')}</button>}
              {uploadFile &&
                <button disabled>
                  <div className="spinner" />
                  {t ('Subiendo el archivo...')}
                </button>}
            </div>
          }
          setShow={setShowModal}
          show={showModal}
          onCloseModal={() => {
            setShowModal (false);
          }}
        >
          <div className="offer-form-body">
            <div className="input-data-check">
              {formik.values.status_validation &&
                <div>
                  Estado:
                  {' '}
                  {statusValidationLabels[formik.values.status_validation]}
                </div>}
              <div />
            </div>

            <div className="input-data profile-item-imagen-select-pack">
              <span>Archivo a publicar</span>
              <div className="select-image-pack-item">
                <input
                  id="frontPage"
                  type="file"
                  name="frontPage"
                  onChange={event => {
                    const fileItem = event.target.files[0];
                    formik.handleChange (event);
                    setSelectedImageFrontPage (fileItem);
                    formik.setFieldValue ('front_page', fileItem);
                  }}
                />
                {!selectedImageFrontPage &&
                  !formik.values.front_page &&
                  <div className="item-image-pack">
                    <label
                      htmlFor="frontPage"
                      className="profile-container-label-image-pack"
                    >
                      <FontAwesomeIcon
                        icon={faFile}
                        className="edit-image-pack"
                      />
                      <div className="text-select-img">
                        {t ('Seleccione un archivo')}{' '}
                      </div>
                    </label>
                  </div>}
                {(formik.values.front_page || selectedImageFrontPage) &&
                  buildShowComponent ()}
              </div>
              {formik.touched.front_page && formik.errors.front_page
                ? <div className="error-pack">{formik.errors.front_page}</div>
                : <div className="error-pack" />}
            </div>

            {profileUser &&
              profileUser.authorized_sell &&
              <div className="section-input-pack">
                <div className="input-data">
                  <span>{t ('Precio del pack en COP')}</span>
                  <input
                    id="price"
                    type="number"
                    name="price"
                    placeholder="0"
                    value={formik.values['price']}
                    onChange={event => {
                      const value = event.target.value === ''
                        ? ''
                        : parseInt (event.target.value, 10);
                      formik.setFieldValue ('price', value);
                      const valorPorcentaje =
                        value * profileUser.profit_percentage;
                      formik.setFieldValue (
                        'amount',
                        Math.floor ((value + valorPorcentaje) / 50)
                      );
                      setAmountOriginal (Math.floor (value / 50));
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.price &&
                    formik.errors.price &&
                    <div className="error-pack">{formik.errors.price}</div>}
                </div>
              </div>}
            <div className="input-data">
              <span>{t ('Código de desbloqueo (opcional)')}</span>
              <input
                id="unlock_code"
                type="string"
                name="unlock_code"
                placeholder="codigo desbloqueo"
                value={formik.values['unlock_code']}
                onChange={event => {
                  formik.setFieldValue ('unlock_code', event.target.value);
                }}
              />
              {formik.touched.unlock_code &&
                formik.errors.unlock_code &&
                <div className="error-pack">{formik.errors.unlock_code}</div>}
            </div>
            <div className="input-data rich-text">
              <span>{t ('description')}</span>
              <TextareaRich
                value={value && value.description ? value.description : ''}
                id="description"
                name="description"
                onChange={(a, value, c, dataInitial) => {
                  formik.setFieldValue ('description', value);
                }}
              />
              {formik.touched.description && formik.errors.description
                ? <div className="error-pack">{formik.errors.description}</div>
                : <div className="error-pack" />}
            </div>
          </div>
        </Modal>}
    </form>
  );
};

export default React.memo (PackForm);

function initialValuesData (dataInitial) {
  return {
    created: dataInitial && dataInitial.created
      ? formatDate (dataInitial.created)
      : '',
    id: dataInitial && dataInitial.id ? dataInitial.id : null,
    status_validation: dataInitial && dataInitial.status_validation
      ? dataInitial.status_validation
      : '',
    user_content_creator: dataInitial && dataInitial.user_content_creator
      ? dataInitial.user_content_creator
      : null,
    unlock_code: dataInitial && dataInitial.unlock_code
      ? dataInitial.unlock_code
      : '',
    unlock_code_active: dataInitial && dataInitial.unlock_code_active
      ? dataInitial.unlock_code_active
      : false,
    payment_messages: dataInitial && dataInitial.payment_messages
      ? dataInitial.payment_messages
      : false,
    slug: dataInitial && dataInitial.slug ? dataInitial.slug : '',
    amount: dataInitial && dataInitial.amount ? dataInitial.amount : 0,
    price: dataInitial && dataInitial.price ? dataInitial.price : 0,
    description: dataInitial && dataInitial.description
      ? dataInitial.description
      : '',
    tags_data: dataInitial && dataInitial.tags_data
      ? dataInitial.tags_data
      : [],
    front_page: dataInitial && dataInitial.front_page
      ? dataInitial.front_page
      : '',
    show: dataInitial && dataInitial.hasOwnProperty ('show')
      ? dataInitial.show
      : false,
  };
}
