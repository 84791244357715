import axios from "axios";
import FormData from "form-data";
import { API_URL, HTTP_METHOD } from "../../utils/Constant";

export async function ProfileService(type, data, foto, front_page) {
  if (type === HTTP_METHOD.GET) {
    try {
      const url = `${API_URL}/api/perfil/`;
      const res = await axios.get(url);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else if (res.status === 400) {
        throw new Error(res.status);
      } else if (res.status === 401) {
        throw new Error("Unauthorized");
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        if (error.response.status === 401) {
          throw new Error("Unauthorized");
        }
      }

      console.error(error);
      throw error;
    }
  }
  if (type === HTTP_METHOD.PUT) {
    const url = `${API_URL}/api/perfil/`;
    const form = new FormData();

    Object.keys(data).forEach((key) => {
      if (key !== "foto" && key !== "front_page" ) {
        form.append(key, data[key]);
      } 
    });

    if (foto) {
      form.append("foto", foto);
    }

    if (front_page) {
      form.append("front_page", front_page);
    }

    try {
      const res = await axios.put(url, form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    } catch (error) {
      if (
        error.message === "logout-app" ||
        error.message === "Unauthorized" ||
        (error.code === "ERR_BAD_REQUEST" &&
          error.response &&
          error.response.status === 401)
      ) {
        throw new Error("exit");
      }

      if (error.code === "ERR_BAD_REQUEST") {
        if (error.response.status === 400) {
          if (error.response.data) {
            let message = [];
            Object.keys(error.response.data).forEach((key) => {
              message.push(error.response.data[key]);
            });
            throw message;
          }
        }
      }
      throw error;
    }
  }
}
