import React, { useState, useEffect, useCallback } from "react";
import "./offer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faPenToSquare,
  faCircleCheck,
  faEarthAmericas,
} from "@fortawesome/free-solid-svg-icons";
import PrincipalTemplate from "../commons/template/principalTemplate";
import { t } from "i18next";
import { OfferService } from "./offer.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//import OffertItem from "./offertItem";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextareaRich from "../html/textareaRich/textareaRich";
import Modal from "../commons/modal/modal";
import MessageManager from "../commons/message/messageManager";
import { TYPE_MESSAGE } from "../../utils/Constant";

export default function OfferForm(props) {
  const { showModal, setShowModal, onChange, isEdit, value } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [messageManagerState, setMessageManagerState] = useState({
    show: false,
    type: TYPE_MESSAGE.INFO,
    title: "a",
    messageText: "b",
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValuesData(value),
    validationSchema: Yup.object({
      name: Yup.string().required("Ingrese un titulo"),
      description: Yup.string().required("Ingrese una descripción"),
    }),
    onSubmit: async (values) => {
      try {
        if (isEdit) {
          value.name = values.name;
          value.description = values.description;
          const dataItem = await OfferService("PUT", value);
          onChange(dataItem);
          handleMessage(true, "", t("saveMessage"), TYPE_MESSAGE.SUCCESS);
          // alert("Datos actualizados");
          setShowModal(false);
        } else {
          const dataItem = await OfferService("POST", values);
          onChange(dataItem);
          //alert("Datos Creados");
          handleMessage(true, "", t("saveMessage"), TYPE_MESSAGE.SUCCESS);
          setShowModal(false);
        }
      } catch (error) {
        if (error.message === "exit") {
          navigate("/");
        } else {
          setErrorMessages(error);
        }
      }
    },
  });

  const handleMessage = (show, title, messageText, type) => {
    setMessageManagerState({
      show: show,
      type: type,
      title: title,
      messageText: messageText,
    });
    setTimeout(() => {
      setMessageManagerState({
        show: false,
        type: TYPE_MESSAGE.INFO,
        title: "",
        messageText: "",
      });
    }, 3000);
  };

  useEffect(() => {
    formik.setValues(initialValuesData(value));
  }, [value]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MessageManager
        show={messageManagerState.show}
        title={messageManagerState.title}
        messageText={messageManagerState.messageText}
        type={messageManagerState.type}
        onClose={() => {
          setMessageManagerState({
            show: false,
            type: TYPE_MESSAGE.INFO,
            title: "a",
            messageText: "b",
          });
        }}
      />

      {showModal && (
        <Modal
          footer={
            <div className="input-data offer-btn-add">
              <button type="submit">{t("add")} </button>
            </div>
          }
          setShow={setShowModal}
          show={showModal}
        >
          <div className="offer-form-body">
            <div className="input-data">
              <span>{t("title")}</span>
              <input
                id="name"
                type="name"
                name="name"
                value={formik.values["name"]}
                onChange={(event) => {
                  formik.setFieldValue("name", event.target.value);
                }}
              />
              {formik.errors.name ? (
                <div className="error">{formik.errors.name}</div>
              ) : (
                <div className="error"></div>
              )}
            </div>
            <div className="input-data rich-text">
              <span>{t("information")}</span>
              <TextareaRich
                value={value && value.description ? value.description : ""}
                id="description"
                name="description"
                onChange={(a, value, c, dataInitial) => {
                  formik.setFieldValue("description", value);
                }}
              />
              {formik.errors.description ? (
                <div className="error">{formik.errors.description}</div>
              ) : (
                <div className="error"></div>
              )}
            </div>
          </div>
        </Modal>
      )}
    </form>
  );
}

function initialValuesData(dataInitial) {
  return {
    name: dataInitial && dataInitial.name ? dataInitial.name : "",
    description:
      dataInitial && dataInitial.description ? dataInitial.description : "",
  };
}
