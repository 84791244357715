import axios from "axios";
import { API_URL } from "../../utils/Constant";

export async function PageService(type, data, file) {
  if (type === "GET") {
    try {
      const url = `${API_URL}/api/page/`;
      const res = await axios.get(url);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else if (res.status === 400) {
        throw new Error(res.status);
      } else if (res.status === 401) {
        throw new Error("Unauthorized");
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        if (error.response.status === 401) {
          throw new Error("Unauthorized");
        }
      }

      console.error(error);
      throw error;
    }
  }
  if (type === "PUT") {
    const url = `${API_URL}/api/page/`;
    try {
      const res = await axios.put(url, data, {});
      return res.data;
    } catch (error) {
      if (
        error.message === "logout-app" ||
        error.message === "Unauthorized" ||
        (error.code === "ERR_BAD_REQUEST" &&
          error.response &&
          error.response.status === 401)
      ) {
        throw new Error("exit");
      }

      if (error.code === "ERR_BAD_REQUEST") {
        if (error.response.status === 400) {
          if (error.response.data) {
            let message = [];
            Object.keys(error.response.data).forEach((key) => {
              message.push(error.response.data[key]);
            });
            throw message;
          }
        }
      }
      throw error;
    }
  }
}
