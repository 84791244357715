import React, {useState, useCallback, useEffect} from 'react';
import './galery.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faEarthAmericas} from '@fortawesome/free-solid-svg-icons';
import PrincipalTemplate from '../commons/template/principalTemplate';
import {useTranslation} from 'react-i18next';
import ItemSelect from './itemSelect';
import {GaleryService} from './galery.service';
import {useNavigate} from 'react-router-dom';
import Information from '../commons/information/information';
import {TYPE_MESSAGE, KEY_STORAGE_INFORMATION} from '../../utils/Constant';
import {useLogin} from '../../hooks/useLogin';

export default function Galery () {
  const navigate = useNavigate ();
  const {getUrlPage} = useLogin ();
  const {t} = useTranslation ();
  const [openMenu, setOpenMenu] = useState (false);
  const [errorMessages, setErrorMessages] = useState ([]);
  const [imagesList, setImagesList] = useState ([]);
  const [listImagesCount, setListImagesCount] = useState ([0, 1, 2, 3, 4, 5]);
  const [messageManagerState, setMessageManagerState] = useState ({
    show: false,
    type: TYPE_MESSAGE.INFO,
    title: 'a',
    messageText: 'b',
  });

  const getDataImage = useCallback (async () => {
    try {
      const data = await GaleryService ('GET');
      setImagesList (data);
    } catch (error) {
      if (error.message === 'exit') {
        navigate ('/');
      } else if (error) {
        setErrorMessages (error);
      }
    }
  }, []);

  const handleMessage = (show, title, messageText, type) => {
    setMessageManagerState ({
      show: show,
      type: type,
      title: title,
      messageText: messageText,
    });
    setTimeout (() => {
      setMessageManagerState ({
        show: false,
        type: TYPE_MESSAGE.INFO,
        title: '',
        messageText: '',
      });
    }, 3000);
  };

  useEffect (() => {
    (async () => {
      getDataImage ();
    }) ();
  }, []);

  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu (true);
        }}
      />
    );
  };

  const rightButton = () => {
    return (
      <FontAwesomeIcon
        icon={faEarthAmericas}
        className="icon-facebook"
        onClick={() => {
          window.open (getUrlPage ());
        }}
      />
    );
  };

  return (
    <PrincipalTemplate
      headerLeft={leftButton ()}
      headerRight={rightButton ()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t ('galery')}
      footerActive="galery"
    >
      <div className="container-imagen">
        <Information
          title={t ('remember')}
          keyStorage={KEY_STORAGE_INFORMATION.GALERY}
        >
          {t ('messageValidationImage')}
        </Information>
        <div className="imagen-section">
          {listImagesCount.map (count => {
            if (imagesList[count]) {
              return (
                <ItemSelect
                  key={count}
                  item={imagesList[count]}
                  nameFile={`image-${count}`}
                />
              );
            } else {
              return (
                <ItemSelect
                  key={count}
                  item={null}
                  nameFile={`image-${count}`}
                  onChange={e => {
                    //alert ('hola');
                  }}
                />
              );
            }
          })}
        </div>
      </div>
    </PrincipalTemplate>
  );
}
