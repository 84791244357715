import React, { useEffect, useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./textareaRich.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export default function TextareaRich(props) {
  const { value, onChange, id, name, extra } = props;
  const [contentState, setContentState] = useState(); // ContentState JSON

  useEffect(() => {
    const contentBlock = htmlToDraft(value || "");
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      setContentState(editorState);
    }
  }, [value]);

  const toolbarOptions = {
    options: ["emoji", "inline", "list", "history"],
    //options: ["emoji", "inline", "fontSize", "list", "history"],
    list: {
      //options: ["unordered", "ordered"],
      options: ["unordered"],
    },
    history: {
      options: ["undo", "redo"],
    },
    inline: {
      // options: ["bold", "italic", "underline", "strikethrough"],
      options: ["bold", "italic", "strikethrough"],
    },
  };

  const editorStateChange = (editorState) => {
    onChange(
      id,
      draftToHtml(convertToRaw(editorState.getCurrentContent())),
      extra
    );
    setContentState(editorState);
  };
  return (
    <Editor
      editorState={contentState}
      onEditorStateChange={editorStateChange}
      wrapperClassName="wrapper-class"
      editorClassName="editor-class"
      toolbarClassName="toolbar-class"
      toolbar={toolbarOptions}
    />
  );
}
