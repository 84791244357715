import React from "react";
import Login from "../components/login/login/login";

export default function LoginRouter() {
  return (
    <div>
      <Login />
    </div>
  );
}
