import React, { useState, useEffect } from 'react';
import './multimediaItem.css'; // Asegúrate de que esta ruta es correcta
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons';
import { MultimediaService } from '../multimedia.service';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Modal from '../../commons/modal/modal';
import MessageManager from '../../commons/message/messageManager';
import { TYPE_MESSAGE } from '../../../utils/Constant';
import { gentNameFile } from '../../../utils/Utils';
import { isAudio, isImage, isVideo } from '../../../utils/ValidationTypeFile';
import Checkbox from '../../html/checkbox/checkbox';

export default function MultimediaForm(props) {
  const { showModal, setShowModal, onChangeModal, isEdit, value } = props;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [listFileSelected, setListFileSelected] = useState([]);
  const [dataStateList, setDataStateList] = useState();
  const [selectedImagePreviewContent, setSelectedImagePreviewContent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [messageManagerState, setMessageManagerState] = useState({
    show: false,
    type: TYPE_MESSAGE.INFO,
    title: 'a',
    messageText: 'b',
  });

  useEffect(() => {
    if (isEdit) {
      setListFileSelected([{file_0: value}])
      setDataStateList({file_0: value})
    }
  }, [])

  useEffect(() => {
    if (value && value.file) {
      const nameFile = gentNameFile(value.file);
      value['name'] = nameFile;
      setListFileSelected([{ ...value, id: Date.now() }]);
    } else {
      if (!isEdit) {
        setDataStateList({});
        setListFileSelected([]);
      }
    }
  }, [value]);

  const onSubmitData = async values => {
    try {
      setIsSubmitting(true);

      if (isEdit) {
        const dataItem = await MultimediaService(
          'PUT',
          values['file_0'],
          values['file_0'].file,
          values['file_0'].preview_content
        );
        props.onChangeModal(dataItem);
        handleMessage(true, '', t('saveMessage'), TYPE_MESSAGE.SUCCESS);
        setShowModal(false);
      } else {
        for (const item of Object.keys(values)) {
          const dataItem = await MultimediaService(
            'POST',
            values[item],
            values[item].file,
            selectedImagePreviewContent
          );
          onChangeModal(dataItem);
        }
      }
      handleMessage(true, '', t('saveMessage'), TYPE_MESSAGE.SUCCESS);
      setShowModal(false);

      setSelectedImagePreviewContent(undefined);
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
    }
  };

  const handleMessage = (show, title, messageText, type) => {
    setMessageManagerState({
      show: show,
      type: type,
      title: title,
      messageText: messageText,
    });
    setTimeout(() => {
      setMessageManagerState({
        show: false,
        type: TYPE_MESSAGE.INFO,
        title: '',
        messageText: '',
      });
    }, 3000);
  };

  const removeFile = id => {
    const newFileList = listFileSelected.filter(file => file.id !== id);
    setListFileSelected(newFileList);
  };

  const buildImagePreView = () => {
    return listFileSelected.map((file, index) => {
      let indexName = 'file_' + index;
      const dataInfo = {
        file: file.file || file,
        preview_content: file.preview_content,
        apply_blur: isEdit ? file.apply_blur : false,
        pack_multimedia: value?.pack_multimedia || null,
        id: value?.id,
      };
      let urlFile = typeof dataInfo.file === 'string' ? dataInfo.file : URL.createObjectURL(file.file);
    
      const commonElements = (
        <div key={file.id} className="item-pre-img">
          {!isEdit && <div className="remove-item-upload" onClick={() => removeFile(file.id)}>X</div>}

          {isImage(file.file.name || file.file) && <img alt="not found" className="preview-file" src={urlFile} />}
          {isVideo(file.file.name || file.file) && (
            <video className="profile-video-pack" controls>
              <source src={urlFile} type={file.file.type} />
              {t('Tu navegador no admite la reproducción de videos.')}
            </video>
          )}
          {isAudio(file.file.name || file.file) && (
            <audio controls>
              <source src={urlFile} type={file.file.type} />
              {t('Tu navegador no admite la reproducción de audios.')}
            </audio>
          )}
          <div className="item-option-file">
            <Checkbox
              name={`apply_blur_${index}`}
              id={`apply_blur_${index}`}
              value={dataStateList[indexName]?.apply_blur }
              formikSetFieldValue={(key, valueInput, item) => {
                dataInfo.apply_blur = valueInput;
                setDataStateList(prevState => ({ ...prevState, [indexName]: dataInfo }));
              }}
            />
            <label htmlFor={`apply_blur_${index}`}>El archivo es de pago</label>
          </div>
        </div>
      );
      return commonElements;
    });
  };

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        const formData = {};
        listFileSelected.forEach((file, index) => {
          const indexName = 'file_' + index;
          formData[indexName] = {
            file: file.file,
            preview_content: dataStateList[indexName]?.preview_content || null,
            apply_blur: dataStateList[indexName]?.apply_blur || false,
            pack_multimedia: value?.pack_multimedia || null,
            id: value?.id
          };
        });
        onSubmitData(formData);
      }}
    >
      <MessageManager
        show={messageManagerState.show}
        title={messageManagerState.title}
        messageText={messageManagerState.messageText}
        type={messageManagerState.type}
        onClose={() => {
          setMessageManagerState({
            show: false,
            type: TYPE_MESSAGE.INFO,
            title: '',
            messageText: '',
          });
        }}
      />
      {showModal && (
        <Modal
          title="Subir contenido"
          footer={
            <div className="btn-save-multimedia">
              <button
                type="submit"
                disabled={isSubmitting || listFileSelected.length === 0}
                className={`btn-save-multimedia-button ${isSubmitting || listFileSelected.length === 0 ? 'disabled' : ''}`}
              >
                {isSubmitting ? t('Guardando...') : t('Guardar')}
              </button>
            </div>
          }
          setShow={setShowModal}
          show={showModal}
          onCloseModal={() => {
            setSelectedImagePreviewContent(null); // Reset selectedImagePreviewContent state
          }}
        >
          <div className="item-multimedia-body">
            <div className="container-file-form">
              <div className="input-data profile-item-imagen-select-pack">
                {buildImagePreView()}
                {!isEdit && 
                <div className="btn-select-files">
                  <input
                    id="files"
                    type="file"
                    name="file"
                    multiple
                    onChange={event => {
                      const files = Array.from(event.target.files).filter(file => file.size <= 25 * 1024 * 1024).map(file => ({ file, id: Date.now() + Math.random() }));
                      if (files.length === event.target.files.length) {
                        setListFileSelected(prevState => [...prevState, ...files]);
                      } else {
                        alert('Algunos archivos son demasiado grandes');
                      }
                    }}
                  />
                  <label htmlFor="files" className="item-edit-multimedia">
                    <FontAwesomeIcon icon={faFileUpload} className="icon-upload" />
                    <p className="item-info-text">Cargar archivos</p>
                    <p className="item-info-download">PNG,JPG, MP3 y MP4 hasta 25MB</p>
                  </label>
                </div>
                }
              </div>
            </div>
          </div>
        </Modal>
      )}
    </form>
  );
}
