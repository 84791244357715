import React, { useState, useEffect } from "react";
import "./modal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import createDOMPurify from "dompurify";

export default function Modal(props) {
  const {
    children,
    open,
    onClose,
    title,
    className,
    footer,
    onCloseModal,
    onClickOutside,
    show,
    setShow,
    
  } = props;

  const [showModal, setShowModal] = useState(true);

  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const content = (
    <div className="modal-container">
      <div className={`modal-content ${className}`}>
        <div className="modal-title">
          <span>{title}</span>
          <FontAwesomeIcon
            icon={faCircleXmark}
            className="icon-close-modal"
            onClick={() => {
              setShowModal(false);
              setShow(false);
              onCloseModal()
            }}
          />
        </div>
        <div className="modal-body">{children}</div>
        <div className="modal-footer">{footer}</div>
      </div>
      <div
        className="modal-background"
        onClick={() => {
          setShowModal(false);
          setShow(false);
        }}
      ></div>
    </div>
  );

  return showModal ? content : null;
}
