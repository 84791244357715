import React, {useState, useEffect, useCallback} from 'react';
import './pack.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faBars,
  faEarthAmericas,
  faInfoCircle,
  faQuestionCircle,
  faCirclePlus,
} from '@fortawesome/free-solid-svg-icons';
import PrincipalTemplate from '../commons/template/principalTemplate';
import {PackService} from './pack.service';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {useLogin} from '../../hooks/useLogin';
import PackItem from './components/packItem';
import PackForm from './components/pack-form';
import {ProfileService} from '../../components/profile/profile.service';
import {KEY_STORAGE} from '../../utils/Constant';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import Watermark from '../commons/watermark/watermark';
import Modal from '../commons/modal/modal';

export default function Pack () {
  const navigate = useNavigate ();
  const {t} = useTranslation ();
  const {getUrlPage} = useLogin ();
  const [openMenu, setOpenMenu] = useState (false);
  const [dataList, setDataList] = useState ([]);
  const [errorMessages, setErrorMessages] = useState ([]);
  const [showModal, setShowModal] = useState (false);
  const [showModalInfo, setShowModalInfo] = useState (false);
  const [showModalHelp, setShowModalHelp] = useState (false);
  const {setItem} = useLocalStorage ();
  const [profileData, setProfileData] = useState ({});

  const getDataPerfil = useCallback (async () => {
    try {
      const data = await ProfileService ('GET');
      setProfileData (data);
      setItem (KEY_STORAGE.USER_PROFILE, data);
    } catch (error) {
      if (error.message === 'exit') {
        navigate ('/');
      } else if (error) {
        setErrorMessages (error);
      }
    }
  }, []);

  const getData = useCallback (async () => {
    try {
      const data = await PackService ('GET');
      setDataList (data.results);
    } catch (error) {
      if (error.message === 'exit') {
        navigate ('/');
      } else if (error) {
        setErrorMessages (error);
      }
    }
  }, []);

  useEffect (() => {
    (async () => {
      getData ();
      getDataPerfil ();
    }) ();
  }, []);

  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu (true);
        }}
      />
    );
  };
  const rightButton = () => {
    return (
      <div>
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="icon-facebook step-item-8"
          onClick={() => {
            setShowModalHelp (true);
          }}
        />
        <FontAwesomeIcon
          icon={faInfoCircle}
          className="icon-facebook step-item-7"
          onClick={() => {
            setShowModalInfo (true);
          }}
        />
        <FontAwesomeIcon
          icon={faEarthAmericas}
          className="icon-facebook step-item-6"
          onClick={() => {
            window.open (getUrlPage ());
          }}
        />
      </div>
    );
  };

  const deletedItem = id => {
    const updatedDataList = dataList.filter (item => item.id !== id);
    setDataList (updatedDataList);
  };

  return (
    <PrincipalTemplate
      headerLeft={leftButton ()}
      headerRight={rightButton ()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t ('Packs')}
      footerActive="pack"
      emailConfirmed={profileData.email_confirmed}
      setShowTour2={setShowModalHelp}
    >
      <div className="offer-btn-plus">
        <FontAwesomeIcon
          icon={faCirclePlus}
          className="icon-plush step-item-5"
          onClick={() => {
            setShowModal (true);
          }}
        />
      </div>
      <PackForm
        value={{name: '', description: ''}}
        showModal={showModal}
        setShowModal={setShowModal}
        onChange={item => {
          setDataList ([item, ...dataList]);
        }}
      />
      <div className="container-pack-global">
        {dataList &&
          dataList.map ((item, index) => {
            return (
              <PackItem
                key={index}
                data={item}
                deleteItem={deletedItem}
                updateData={itemData => {}}
              />
            );
          })}

        {dataList &&
          dataList.length == 0 &&
          <Watermark
            message={'Todavía no has creado ningún Pack'}
            action={() => {
              setShowModal (true);
            }}
          />}
      </div>
      <br />
      <br />
      <br />
      <br />
      <Modal
        children={
          <div className="pending-task">
            <h2>Tareas pendientes </h2>
            <ol>
              <li> Actualiza tu perfil </li>
              <li> Crea tu primer pack </li>
              <li>
                {' '}
                Ajusta la información para que puedan contactarte fácilmente
                {' '}
              </li>
              <li> Verifica como te ven los clientes </li>
            </ol>
          </div>
        }
        onCloseModal={() => {
          setShowModalInfo (false);
        }}
        show={showModalInfo}
        setShow={() => {}}
      />
    </PrincipalTemplate>
  );
}
