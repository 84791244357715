import React, { useEffect, useState } from "react";
import "./principalTemplate.css";
import Header from "../header/header";
import Body from "../body/body";
import Footer from "../footer/footer";
import Menu from "../menu/menu";
import Watermark from "../watermark/watermark";
import { useLogin } from "../../../hooks/useLogin";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import Joyride from 'react-joyride';
import { useLocalStorage } from "../../../hooks/useLocalStorage";
import { KEY_STEP_TOUR } from "../../../utils/Constant";

export default function PrincipalTemplate(props) {
  const { logout, getValidateEmailConfirmed } = useLogin();
  const { setItem, getItem } = useLocalStorage();
  const [runTour, setRunTour] = useState(true);
  const [stepIndex, setStepIndex] = useState(0);

  const navigate = useNavigate();
  useEffect(() => {
  }, []);

  const steps = [
    {
      target: '.step-item-1',
      content: 'Aqui pudes agregar información de como quieres que te contacten',
    },
    {
      target: '.step-item-2',
      content: 'Aqui pudes ver los packs que estas promocionando!',
    },
    {
      target: '.step-item-3',
      content: 'Aqui puedes editar la información de tu perfil',
    },
    {
      target: '.step-item-4',
      content: 'Aqui puedes navegar en las diferentes opciones de la aplicación',
    },
    {
      target: '.step-item-5',
      content: 'Aqui puedes agregar tus packs',
    },
    {
      target: '.step-item-6',
      content: 'Aqui puedes visualizar tu pack como lo verian los clientes',
    },

    {
      target: '.step-item-7',
      content: 'Aqui puedes visualizar las tareas pendientes por realizar',
    },

    {
      target: '.step-item-8',
      content: 'Aqui puedes visualizar esta ayuda guiada',
    },


  ];



  const {
    headerLeft,
    headerRight,
    footerActive,
    headerTitle,
    children,
    menuAction,
    menuActive,
    activeFooter= true,
    activeHeader= true,
    emailConfirmed = true,
    setShowTour2 = () => {}
  } = props;

  const isTourCompleted = getItem(KEY_STEP_TOUR.STEP_1) === 'true';
  const [showTour, setShowTour] = useState(!isTourCompleted);

  const handleTourComplete = (data) => {
    if (data.action === 'close' || data.status === 'finished') {
      setItem(KEY_STEP_TOUR.STEP_1, 'true');
      setShowTour(false);
      setRunTour(false);
    }

     if (data.type === 'step:after') {
       // Actualiza el índice del paso
       setStepIndex((prev) => prev + 1);
     }

  };

  setShowTour2(item => {
    if (item) {
      setShowTour(true)
      setStepIndex(1)
      setRunTour(true)
      setItem(KEY_STEP_TOUR.STEP_1, 'true');
    }
  })



  return (    
    <div className="app-container">
      {
        emailConfirmed 
        ?   
        <>
         {showTour ? (
          <Joyride
            continuous = {true}
            steps={steps}
            showProgress={true}
            run={runTour}
            stepIndex={stepIndex}
            locale={{
              back: 'Anterior',
              close: 'Close',
              last: 'Ultimo',
              next: 'Siguiente',
              open: 'Open the dialog',
              skip: 'Skip',
            }}
            styles={{
              options: {
              },
            }}
            callback={handleTourComplete}
          />) : <></> }
          <Menu active={menuActive} action={menuAction} />
          {activeHeader && <Header title={headerTitle} left={headerLeft} right={headerRight} />}
          <Body>
            <div className="container-initial">{children} </div>
          </Body>
          {activeFooter &&  <Footer active={footerActive} />}
        </>
      : 
      <Body>
        <div className="container-initial">
        <Watermark message={"Aún no has confirmado tu correo electrónico."} action={() => { logout();  navigate("/login");}} btnText="Salir"/>
        </div>
      </Body>
      }
    </div>
  );
}
