import React, { useState, useEffect, useCallback } from "react";
import "./multimedia.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faEarthAmericas,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import PrincipalTemplate from "../commons/template/principalTemplate";
//import { t } from "i18next";
import { MultimediaPackService } from "./multimedia.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLogin } from "../../hooks/useLogin";
import MultimediaItem from "./components/multimediaItem";
import MultimediaForm from "./components/multimedia-form";
import Watermark from "../commons/watermark/watermark";


export default function Multimedia({ id }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getUrlPage } = useLogin();
  const [openMenu, setOpenMenu] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [errorMessages, setErrorMessages] = useState([]);
  const [showModalEdit, setShowModalEdit] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const data = await MultimediaPackService("GET", id);
        setDataList(data);
      } catch (error) {
        if (error.message === "exit") {
          navigate("/");
        } else if (error) {
          setErrorMessages(error);
        }
      }
    })();
  }, []);

  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu(true);
        }}
      />
    );
  };
  const rightButton = () => {
    return (
      <>
        <FontAwesomeIcon
          icon={faEarthAmericas}
          className="icon-facebook"
          onClick={() => {
            window.open(getUrlPage());
          }}
        />
        <FontAwesomeIcon
          icon={faCirclePlus}
          className="icon-pack-action"
          onClick={() => {
            setShowModalEdit(true);
          }}
        />
      </>
    );
  };

  const onChangeModal = (item) => {
    if (dataList && item) {
      setDataList(prevDataList => [item, ...prevDataList]);
    }
  }

  return (
    <PrincipalTemplate
      headerLeft={leftButton()}
      headerRight={rightButton()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t("Subir contenido al pack")}
      footerActive="pack"
    >
      {showModalEdit &&       <MultimediaForm
        isEdit={false}
        value = {null}
        value={{
          id: id,
          pack_multimedia: id,
          file: "",
          preview_content: "",
          apply_blur: false,
          apply_watermark_archivo: true,
          apply_watermark_preview_content: true
        }}
        showModal={showModalEdit}
        setShowModal={setShowModalEdit}
        onChangeModal={onChangeModal}
      />}

      {dataList && dataList.length > 0 &&
        dataList.map((itemData, index) => {

          return <MultimediaItem key={itemData.id} data={itemData} setDataList={setDataList} listaA={dataList} />
        })}

      {dataList && (dataList.length == 0) && <Watermark action={() => {
        setShowModalEdit(true);
      }} message={"Todavía no has subido archivos."}
        btnText={"Subir"}
      />}
      <br />
      <br />
      <br />
      <br />
    </PrincipalTemplate>
  );
}
