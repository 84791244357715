import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

export default function ButtonEmail(props) {
  return (
    <>
      <div className="social-botton-container sign">
        <div onClick={() => props.state()}>
          <FontAwesomeIcon icon={faEnvelope} className="icon-email" /> Continuar
          con Email
        </div>
      </div>
    </>
  );
}
