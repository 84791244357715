import React from "react";

export default function MessageRegistration(props) {
  return (
    <>
      <div className="registration-container">
        <span
          className="registration-link"
          onClick={(event) => {
            event.preventDefault();
            props.state();
          }}
        >
          Iniciar sesión
        </span>
      </div>
    </>
  );
}
