import React, { useState, useEffect } from "react";
import "./information.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";
import { useLocalStorage } from "../../../hooks/useLocalStorage";

export default function Information(props) {
  const { setItem, getItem } = useLocalStorage();
  const { children, title, keyStorage } = props;
  const [closeFieldset, setCloseFieldset] = useState(false);

  useEffect(() => {
    if (getItem(keyStorage)) {
      setCloseFieldset(true);
    } else {
      setItem(keyStorage, false);
      setCloseFieldset(false);
    }
  }, []);

  useEffect(() => {}, [closeFieldset]);

  return (
    <div
      className={
        closeFieldset
          ? "container-message-info "
          : "container-message-info container-message-info-color"
      }
    >
      <div className="message-info-btn">
        <span>{title}</span>
        <span className="close-fieldset">
          <FontAwesomeIcon
            icon={faCircleInfo}
            className={closeFieldset ? " icon-close-fieldset" : "hidden"}
            onClick={() => {
              const statusClose = getItem(keyStorage);
              setCloseFieldset(!statusClose);
              setItem(keyStorage, !statusClose);
            }}
          />
          <FontAwesomeIcon
            icon={faCircleXmark}
            className={closeFieldset ? " hidden" : " icon-close-fieldset"}
            onClick={() => {
              const statusClose = getItem(keyStorage);
              setCloseFieldset(!statusClose);
              setItem(keyStorage, !statusClose);
            }}
          />
        </span>
      </div>
      <div>
        <p className={closeFieldset ? " hidden" : ""}>{children}</p>
      </div>
    </div>
  );
}
