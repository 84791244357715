import axios from 'axios';
import {API_URL} from '../../utils/Constant';

export async function CommentSystemPack (type, packId, text) {
  // Crear comentario
  if (type === 'POST') {
    try {
      const url = `${API_URL}/api/comment/`;
      const res = await axios.post (url, {text, pack_multimedia: packId});
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 401) {
          throw new Error ('Unauthorized');
        }
      }

      console.error (error);
      throw error;
    }
  }
  //Obtener comentarios por id del pack
  if (type === 'GET') {
    try {
      const url = `${API_URL}/api/pack/comment/${packId}/`;
      const res = await axios.get (url);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 401) {
          throw new Error ('Unauthorized');
        }
      }

      console.error (error);
      throw error;
    }
  }
}

export async function CommentSystemPackLike (type, commentId) {
  // Crear comentario
  if (type === 'POST') {
    try {
      const url = `${API_URL}/api/comment/${commentId}/like/`;
      const res = await axios.post (url);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 401) {
          throw new Error ('Unauthorized');
        }
      }

      console.error (error);
      throw error;
    }
  }
}

export async function CommentSystemPackComment (type, packId, text, commentId) {
  // Crear comentario de comentarios
  if (type === 'POST') {
    try {
      const url = `${API_URL}/api/comment/${commentId}/reply/`;
      const res = await axios.post (url, {text, pack_multimedia: packId});
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
    } catch (error) {
      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 401) {
          throw new Error ('Unauthorized');
        }
      }

      console.error (error);
      throw error;
    }
  }
}
