import axios from 'axios';
import {API_URL} from '../../utils/Constant';

export async function PackService (type, data, front_page) {
  if (type === 'GET') {
    try {
      const url = `${API_URL}/api/pack-multimedia/`;
      const res = await axios.get (url);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else if (res.status === 400) {
        throw new Error (res.status);
      } else if (res.status === 401) {
        throw new Error ('Unauthorized');
      } else {
        throw new Error (res.data);
      }
    } catch (error) {
      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 401) {
          throw new Error ('Unauthorized');
        }
      }

      console.error (error);
      throw error;
    }
  }
  if (type === 'PUT') {
    const url = `${API_URL}/api/pack-multimedia/${data.id}/`;
    const form = new FormData ();

    Object.keys (data).forEach (key => {
      if (key !== 'front_page') {
        form.append (key, data[key]);
      }
    });

    if (front_page && front_page !== '') {
      if (front_page.front_page) {
      } else {
        form.append ('front_page', front_page);
      }
    }

    try {
      const res = await axios.put (url, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data;
    } catch (error) {
      if (
        error.message === 'logout-app' ||
        error.message === 'Unauthorized' ||
        (error.code === 'ERR_BAD_REQUEST' &&
          error.response &&
          error.response.status === 401)
      ) {
        throw new Error ('exit');
      }

      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 400) {
          if (error.response.data) {
            let message = [];
            Object.keys (error.response.data).forEach (key => {
              message.push (error.response.data[key]);
            });
            throw message;
          }
        }
      }
      throw error;
    }
  }

  if (type === 'POST') {
    const url = `${API_URL}/api/pack-multimedia/`;
    const form = new FormData ();
    try {
      Object.keys (data).forEach (key => {
        if (key !== 'front_page') {
          form.append (key, data[key]);
        }
      });

      if (front_page) {
        form.append ('front_page', front_page);
      }

      const res = await axios.post (url, form, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return res.data;
    } catch (error) {
      if (
        error.message === 'logout-app' ||
        error.message === 'Unauthorized' ||
        (error.code === 'ERR_BAD_REQUEST' &&
          error.response &&
          error.response.status === 401)
      ) {
        throw new Error ('exit');
      }

      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 400) {
          if (error.response.data) {
            let message = [];
            Object.keys (error.response.data).forEach (key => {
              message.push (error.response.data[key]);
            });
            throw message;
          }
        }
      }
      throw error;
    }
  }

  if (type === 'DEL') {
    const url = `${API_URL}/api/pack-multimedia/${data.id}/`;
    try {
      const res = await axios.delete (url, data);
      return res.data;
    } catch (error) {
      if (
        error.message === 'logout-app' ||
        error.message === 'Unauthorized' ||
        (error.code === 'ERR_BAD_REQUEST' &&
          error.response &&
          error.response.status === 401)
      ) {
        throw new Error ('exit');
      }

      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 400) {
          if (error.response.data) {
            let message = [];
            Object.keys (error.response.data).forEach (key => {
              message.push (error.response.data[key]);
            });
            throw message;
          }
        }
      }
      throw error;
    }
  }
}

export async function PackDetailService (type, id) {
  if (type === 'GET') {
    try {
      const url = `${API_URL}/api/pack-multimedia/${id}/`;
      const res = await axios.get (url);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else if (res.status === 400) {
        throw new Error (res.status);
      } else if (res.status === 401) {
        throw new Error ('Unauthorized');
      } else {
        throw new Error (res.data);
      }
    } catch (error) {
      if (error.code === 'ERR_BAD_REQUEST') {
        if (error.response.status === 401) {
          throw new Error ('Unauthorized');
        }
      }

      console.error (error);
      throw error;
    }
  }
}
