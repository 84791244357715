export const getListError = (body) => {
  const listKey = Object.keys(body);
  const listError = [];
  listKey.forEach((key) => {
    if (body[key]) {
      listError.push(body[key]);
    }
  });
  return listError;
};

export const validateHttp = (text) => {
  const regex = new RegExp("^(http|https)://", "i");
  return regex.test(text);
};


export const gentNameFile = (url)  => {
  // Obtener la última parte de la URL después del último '/' (es decir, el nombre del archivo)
  const partesUrl = url.split('/');
  const nombreArchivo = partesUrl[partesUrl.length - 1];
  
  // Devolver el nombre del archivo
  return nombreArchivo;
}
