import React, {useState, useEffect} from 'react';
import './multimediaItem.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPenToSquare, faDeleteLeft} from '@fortawesome/free-solid-svg-icons';
import MultimediaForm from './multimedia-form';
import {MultimediaService} from '../multimedia.service';
import {isAudio, isImage, isVideo} from '../../../utils/ValidationTypeFile';

export default function MultimediaItem (props) {
  const {data, setDataList} = props;
  const [showModalEdit, setShowModalEdit] = useState (false);
  const [valueEdit, setValueEdit] = useState ({});
  const [dataMultimedia, setDataMultimedia] = useState ({});

  useEffect (() => {
    setDataMultimedia (data);
  }, []);

  const statusValidationLabels = {
    A: 'Aprobado',
    P: 'Pendiente',
    R: 'Rechazado',
    M: 'Modificar',
  };

  return (
    <div className="item-multimedia-container">
      {showModalEdit &&
        <MultimediaForm
          isEdit={true}
          value={valueEdit}
          showModal={showModalEdit}
          setShowModal={setShowModalEdit}
          onChangeModal={item => {
            setDataMultimedia (item);
          }}
        />}
      <div className="item-multimedia-state">
        <div
          className={`item-status-multimedia${dataMultimedia.status_validation}`}
        >
          {statusValidationLabels[dataMultimedia.status_validation]}
        </div>
        <div>
          {dataMultimedia.status_validation !== 'A' &&
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="icon-pack-action"
              onClick={() => {
                setValueEdit (dataMultimedia);
                setShowModalEdit (true);
              }}
            />}
          {dataMultimedia.status_validation !== 'A' &&
            data.order != 1 &&
            <FontAwesomeIcon
              icon={faDeleteLeft}
              className="icon-pack-action"
              onClick={async () => {
                if (
                  window.confirm (
                    '¿Estás seguro de que quieres eliminar este ítem?'
                  )
                ) {
                  if (
                    await MultimediaService (
                      'DEL',
                      {},
                      null,
                      null,
                      dataMultimedia.id
                    )
                  ) {
                    setDataList (tempDataList => {
                      const dataRe = tempDataList.filter (
                        item => item.id != dataMultimedia.id
                      );
                      return dataRe;
                    });
                  }
                }
              }}
            />}
        </div>
      </div>
      <div className="item-mutimedia-item">
        <div>
          {isVideo (dataMultimedia.preview_content) &&
            <span>Video (Gratuito)</span>}
          {isImage (dataMultimedia.preview_content) &&
            <span>Imagen Gratuita</span>}
          {isAudio (dataMultimedia.preview_content) &&
            <span>Audio Gratuito</span>}
          {isVideo (dataMultimedia.preview_content) &&
            <video controls>
              <source src={dataMultimedia.preview_content} type="video/mp4" />
              Tu navegador no admite el elemento de video.
            </video>}
          {isImage (dataMultimedia.preview_content) &&
            <img src={dataMultimedia.preview_content} />}
          {isAudio (dataMultimedia.preview_content) &&
            <audio controls>
              <source src={dataMultimedia.preview_content} type="audio/mpeg" />
              Tu navegador no admite la reproducción de audio.
            </audio>}
        </div>
        <div className="label-type-multimedia">
          {isVideo (dataMultimedia.file) && <span>Video (Pago)</span>}
          {isImage (dataMultimedia.file) && <span>Imagen (Pago)</span>}
          {isAudio (dataMultimedia.file) && <span>Audio (Pago)</span>}
          {isVideo (dataMultimedia.file) &&
            <video controls>
              <source src={dataMultimedia.file} type="video/mp4" />
              Tu navegador no admite el elemento de video.
            </video>}
          {isImage (dataMultimedia.file) && <img src={dataMultimedia.file} />}
          {isAudio (dataMultimedia.file) &&
            <audio controls>
              <source src={dataMultimedia.file} type="audio/mpeg" />
              Tu navegador no admite la reproducción de audio.
            </audio>}
        </div>
      </div>
    </div>
  );
}
