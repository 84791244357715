import React, {useState, useEffect, useCallback, useRef} from 'react';
import './historyWindow.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars, faEarthAmericas} from '@fortawesome/free-solid-svg-icons';
import PrincipalTemplate from '../commons/template/principalTemplate';
import {useTranslation} from 'react-i18next';
import {ProfileService} from '../../components/profile/profile.service';
import {PackDetailService} from '../pack/pack.service';
import {useNavigate} from 'react-router-dom';
import {API_URL, KEY_STORAGE} from '../../utils/Constant';
import {useLocalStorage} from '../../hooks/useLocalStorage';
import {MultimediaPackService} from '../multimedia/multimedia.service';
import html2canvas from 'html2canvas';
import iconPlay from '../../assets/images/ico-audio.png';

// Import the qrcode.react library
//import {QRCodeSVG} from 'qrcode.react';
import QRCode from 'qrcode.react';

export default function HistoryWindow({id, pack}) {
  const {setItem} = useLocalStorage ();
  const navigate = useNavigate ();
  const {t} = useTranslation ();
  const [openMenu, setOpenMenu] = useState (false);
  const [profileData, setProfileData] = useState ({});
  const [errorMessages, setErrorMessages] = useState ([]);
  const [dataMultimediaList, setDataMultimediaList] = useState ([]);
  const [dataPackDetail, setDataPackDetail] = useState ([]);
  const captureRef = useRef (null);

  const contieneMp3 = url => {
    try {
      // Crear una instancia de URL para validar la estructura de la URL
      const parsedUrl = new URL (url);

      // Extraer la parte del path de la URL
      const path = parsedUrl.pathname;

      // Verificar si el path termina en '.mp3'
      const esMp3 = /\.mp3$/i.test (path);

      return esMp3;
    } catch (e) {
      // En caso de que la URL no sea válida, devolver false
      return false;
    }
  };

  const renderItemImageHistory = () => {
    if (dataMultimediaList.length > 0) {
      return (
        <div className="item-image-history">
          {dataMultimediaList.map ((item, index) => {
            if (contieneMp3 (item.preview_content)) {
              return (
                <img
                  key={'img' + index}
                  alt="not fount"
                  className="front-page-item-history"
                  src={iconPlay}
                />
              );
            } else {
              return (
                <img
                  key={'img' + index}
                  alt="not fount"
                  className="front-page-item-history"
                  src={item.preview_content}
                />
              );
            }
          })}
        </div>
      );
    } else {
      return null;
    }
  };

  // State to store the generated QR code value
  const [qrCodeValue, setQrCodeValue] = useState ('');

  const handleCaptureScreenshot = () => {
    const captureNode = captureRef.current;
    const originalHeight = captureNode.style.height;
    captureNode.style.height = captureNode.scrollHeight + 'px';

    html2canvas (captureRef.current, {
      useCORS: true,
      allowTaint: true,
      logging: false,
    }).then (canvas => {
      // Restaura el tamaño original del contenedor captureRef
      captureNode.style.height = originalHeight;

      // Convierte el canvas en un objeto Blob
      canvas.toBlob (blob => {
        // Crea un enlace para descargar la imagen
        const downloadLink = document.createElement ('a');
        downloadLink.href = URL.createObjectURL (blob);
        downloadLink.download = dataPackDetail.slug + '.png'; // Nombre de archivo de descarga
        downloadLink.click ();

        // Limpia los recursos del enlace
        URL.revokeObjectURL (downloadLink.href);
      }, 'image/png'); // Formato de imagen (en este caso, PNG)
    });
  };

  // Function to generate the QR code from the desired data
  const generateQRCode = useCallback (
    () => {
      // Assuming you want to use the `dataPackDetail.front_page` as the data for the QR code
      //const qrData = dataPackDetail.front_page;

      const profileItem = profileData;
      //debugger
      // const qrData = `https://contenidohot.net/pack/${dataPackDetail.slug}`
      if (
        profileItem &&
        profileItem.slug &&
        dataPackDetail &&
        dataPackDetail.slug
      ) {
        //const qrData = `https://client.contenidohot.net/pack-public/${profileItem.slug}/${dataPackDetail.slug}`;
        const qrData = `  https://client.contenidohot.net/creator/${profileItem.slug}/`;

      

        setQrCodeValue (qrData);
      }
    },
    [dataPackDetail.front_page]
  );

  const getDataMultimedia = useCallback (async () => {
    try {
      let data = await MultimediaPackService ('GET', id);
      data = data.filter (item => item.status_validation === 'A');
      const newDataMultimediaList = data.slice (0, 4);
      setDataMultimediaList (newDataMultimediaList);
    } catch (error) {
      if (error.message === 'exit') {
        navigate ('/');
      } else if (error) {
        setErrorMessages (error);
      }
    }
  }, []);

  const getDataPerfil = useCallback (async () => {
    try {
      const data = await ProfileService ('GET');
      setProfileData (data);
      setItem (KEY_STORAGE.USER_PROFILE, data);
    } catch (error) {
      if (error.message === 'exit') {
        navigate ('/');
      } else if (error) {
        setErrorMessages (error);
      }
    }
  }, []);

  const getDataPack = useCallback (async () => {
    try {
      const data = await PackDetailService ('GET', id);
      //debugger
      setDataPackDetail (data);
    } catch (error) {
      console.error (error);
    }
  }, []);

  useEffect (
    () => {
      // Generate the QR code when the component mounts or when `dataPackDetail.front_page` changes
      generateQRCode ();
    },
    [generateQRCode, dataPackDetail.front_page]
  );

  useEffect (() => {
    (async () => {
      getDataPerfil ();
      getDataMultimedia ();
      getDataPack ();
    }) ();
  }, []);

  const leftButton = () => {
    return (
      <FontAwesomeIcon
        icon={faBars}
        className="icon-facebook step-item-4"
        onClick={() => {
          setOpenMenu (true);
        }}
      />
    );
  };

  const rightButton = () => {
    return (
      <div>

        <FontAwesomeIcon
          icon={faEarthAmericas}
          className="icon-facebook"
          onClick={() => {
            const data = profileData;
            window.open (API_URL + '/' + data.slug);
          }}
        />
      </div>
    );
  };

  return (
    <PrincipalTemplate
      headerLeft={leftButton ()}
      headerRight={rightButton ()}
      menuAction={setOpenMenu}
      menuActive={openMenu}
      headerTitle={t ('')}
      footerActive="perfil"
      activeFooter={false}
      activeHeader={false}
    >
      <div className="container-history" ref={captureRef}>
        <div className="container-image-initial">
          <img
            alt="not fount"
            className="img-initial-history"
            src={dataPackDetail.front_page}
          />
          <div className="container-item-image">
            <div
              className={
                dataMultimediaList && dataMultimediaList.length > 0
                  ? 'qr-code-container '
                  : 'qr-code-container2'
              }
            >
              {qrCodeValue &&
                <QRCode
                  value={qrCodeValue}
                  size={110}
                  level="L"
                  includeMargin={false}
                  renderAs="svg"
                />}
            </div>
            <div className="container-domain">
              <p>contenidohot. net/{profileData.userPublic}</p>
            </div>

            {renderItemImageHistory ()}
            {/* <div className="item-image-history">
                            {dataMultimediaList &&

                                dataMultimediaList.map((item, index) => {

                                    return <img key={"img" + index}
                                        alt="not fount"
                                        className="front-page-item-history"
                                        src={
                                            item.preview_content
                                        }
                                    />
                                })


                            }
                        </div> */}
          </div>

        </div>

        {/* Botón para capturar la pantalla */}

        {/* 
                <img
                alt="not fount"
                className="profile-img"
                src={
                    profileData.foto
                }
            /> */}

      </div>

      {/* TODO: desbloquear para activar el boton de descargar imagen 
      
            <FontAwesomeIcon
                icon={faFileDownload}
                onClick={handleCaptureScreenshot} className="screenshot-download"
        /> */}

    </PrincipalTemplate>
  );
}
