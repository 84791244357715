import axios from "axios";
import { API_URL, HTTP_METHOD } from "../../utils/Constant";


export async function SalesHistoryService(type) {
  if (type === HTTP_METHOD.GET) {
    try {
      const url = `${API_URL}/api/token-transactions/`;
      const res = await axios.get(url);
      if (res.status === 200 || res.status === 201) {
        return res.data;
      } else if (res.status === 400) {
        throw new Error(res.status);
      } else if (res.status === 401) {
        throw new Error("Unauthorized");
      } else {
        throw new Error(res.data);
      }
    } catch (error) {
      if (error.code === "ERR_BAD_REQUEST") {
        if (error.response.status === 401) {
          throw new Error("Unauthorized");
        }
      }

      console.error(error);
      throw error;
    }
  }
}


