import React from "react";
import "./message.css";
import { TYPE_MESSAGE } from "../../../utils/Constant";
import Message from "./message";
const MessageManager = (props, ref) => {
  const { type, title, messageText, show, onClose } = props;

  const getComponentByType = (type) => {
    switch (type) {
      case TYPE_MESSAGE.SUCCESS:
        return (
          <Message
            type={TYPE_MESSAGE.SUCCESS}
            title={title}
            messageText={messageText}
            show={show}
            ref={ref}
            onClose={onClose}
          />
        );
      case TYPE_MESSAGE.ERROR:
        return (
          <Message
            type={TYPE_MESSAGE.ERROR}
            title={title}
            messageText={messageText}
            show={show}
            ref={ref}
            onClose={onClose}
          />
        );
      case TYPE_MESSAGE.WARNING:
        return (
          <Message
            type={TYPE_MESSAGE.WARNING}
            title={title}
            messageText={messageText}
            show={show}
            ref={ref}
            onClose={onClose}
          />
        );
      default:
        return (
          <Message
            type={TYPE_MESSAGE.INFO}
            title={title}
            messageText={messageText}
            show={show}
            ref={ref}
            onClose={onClose}
          />
        );
    }
  };
  return getComponentByType(type);
};

export default React.forwardRef(MessageManager);
