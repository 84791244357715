import React, { useEffect } from "react";
import "./checkbox.css";

export default function Checkbox(props) {
  const { value, formikSetFieldValue, id, name, extra } = props;

  useEffect(() => {}, [value]);

  const handleChange = () => {
    formikSetFieldValue(id, !value, extra);
  };

  return (
    <span className="checkbox" key={id + "_content"}>
      <input
        id={id}
        name={name}
        type="checkbox"
        checked={value}
        key={id + "_check"}
        onChange={handleChange}
      />
      <span className="wrapper" onClick={handleChange}>
        <span className="knob"></span>
      </span>
    </span>
  );
}
