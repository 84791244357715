import React from "react";

export default function MessageRegistration(props) {
  return (
    <>
      <div className="registration-container">
        {/* Aún no estás registrado ? */}
        <br />
        <br />
        {/* solicita tu cuenta a info@contenidohot.com */}
        {/* TODO: descomentar para actiar la seccion de registro
        
        <span
          className="registration-link"
          onClick={(event) => {
            event.preventDefault();
            props.state();
          }}
        >
          {" "}
          Regístrate
        </span> */}
      </div>
    </>
  );
}
